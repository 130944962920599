import React from "react";
import { AuthorizationLayoutRoutes } from "routes";
import { SiteFooter } from "components/Layout";

import { SplashScreen } from "components/SiteArt/Loader/Loader";
import { useSelector } from "react-redux";

const AuthorizationLayout = () => {
  const loginStatus = useSelector(state=> state.splash.loginInprogress)

  return (
    <>
    {loginStatus ? 
     
     <SplashScreen/>
    
      :<div className="authorization-layout">
        <div className="authorization-layout__main">
          <AuthorizationLayoutRoutes />
        </div>
        {/* <SiteFooter /> */}
      </div>
    }

    </>
  );
};

export default AuthorizationLayout;
