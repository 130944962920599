//general states to be used used througout app
export const STATE = Object.freeze({
  LOADING: "loading",
  WARNING: "warning",
  ERROR: "error",
  COMPLETED: "completed",
  SELECTED: "selected",
  NOT_SELECTED: "not selected",
});

// match key for each check
export const keyMatchData = {
  'Politically Exposed Persons': 'details',
  'Global Sanctions': 'details',
  "AML Penalties": 'match_name',
  'Adverse Press Coverage': 'match_details',
  'Human Rights Violations': 'details',
  'India Watchlists': 'details',
  'Suspected Shell Companies Check': 'details',
  'International Consortium of Investigative Journalists Watchlists':'details',
  'Politically Exposed Individuals': 'details',
  'State Owned Enterprises': 'details',
  'Canada Watchlists':'details',
  'International Watchlists':'details',
  'Singapore Watchlists':'details',
  'United States Watchlists':'details',
  'Country Regimes Watchlists':'details',
  'United Kingdom Watchlists':'details',
  'United Arab Emirates Watchlists': 'details',
  'Nigeria Watchlists':'details',
  'Kenya Watchlists':'details',
  'South Africa Watchlists':'details',
  'Rwanda Watchlists':'details',
  'Mauritius Watchlists':'details',
  'Ethiopia Watchlists':'details',
  'Cayman Islands Watchlists':'details',
  'United Against Nuclear Iran (UANI) Check':'details',
  'Indonesia Watchlists':'details',
  'Israel Watchlists':'details',
  'Malaysia Watchlists':'details',
  'Karnataka Bank Watchlist':'details',
  'Cambodia Watchlists':'details',
  'Brunei Watchlists':'details',
  'Vietnam Watchlists':'details',
  'Philippines Watchlists':'details',
  'Thailand Watchlists':'details',
  'Namibia Watchlists':'details',
  'Ghana Watchlists':'details',
  'France Watchlists':'details',
  'Italy Watchlists':'details',
  'Australia Watchlists':'details',
  'Japan Watchlists':'details',
  'New Zealand Watchlists':'details',
  'Zambia Watchlists':'details',
  'Angola Watchlists' :'details',
  'Zimbabwe Watchlists':'details',
  'Seychelles Watchlists':'details',
  'Uganda Watchlists':'details',
  'Cameroon Watchlists':'details',
  'Malawi Watchlists':'details',
  'Azerbaijan Watchlists':'details',
  'Qatar Watchlists':'details',
  'Kuwait Watchlists':'details',
  'Cannabis Related Entities':'details',
  'Australia Watchlists':'details',
  'Pakistan Watchlists':'details',
  'Hong Kong Watchlists':'details',
  'Iraq Watchlists':'details',
}
export const checkMappingArray=[
  "Politically Exposed Persons","Global Sanctions","Identifiers"
]


// to show match string put the key of that string in the array
export const showMatchString = ['Alias', 'full_name', 'Name','entities','SummaryAddData', 'Full Name', 'Entity Name','Country Name','Official Country Name'];