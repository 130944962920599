
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { tabActions } from "services/Redux";
import { SITE_PATHS } from "config";
import { NavLink } from "react-router-dom";
import moment from "moment";
import home from 'assets/images/icons/Home-new.svg';
// import contact from 'assets/svg/Contact.svg';
import notification from 'assets/images/icons/NotificationIcon.svg';
import crossSm from 'assets/images/icons/tabClose.svg';
// import { LabelData } from "config/lable.js"
import { Box, Button, Tab, Tabs } from "@material-ui/core";
import iconAdminProfile from "assets/images/icon 02.svg";
import NotificationCenter from "./NotificationCenter";
import Logo from "assets/images/icons/Zigram-profile.svg";
import { LibraryAddCheckRounded } from "@material-ui/icons";

function SiteHeader() {
  const { push } = useHistory();
  const location = useLocation()
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const intervalID = setInterval(() => {
      setTime(new Date());
    }, 1000); // Update every 1 second

    // Clean up the interval on component unmount
    return () => clearInterval(intervalID);
  }, []);

  const { tabData, selectedTab, selectedTabFilter, pageTabFilter,selectedPageTabFilter } = useSelector(state => state.tabs);
  const { userData, userProfilePicture } = useSelector(state => state.user);
  const dispatch = useDispatch();

  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    let stateV = localStorage.getItem("state");
    let stateB = stateV && JSON.parse(stateV)
    if (location.state === undefined && stateB) {
      location.state = stateB
    }
  }, [])
  useEffect(() => {
    if (!location.pathname.includes("/home")) {
      if (location?.state !== undefined) {
        localStorage.setItem(
          "state", JSON.stringify(location?.state)
        )
      }
    } else {
      localStorage.removeItem("state")
    }
  }, [location.state])
  useEffect(() => {
    onTop()
  }, [location.pathname]);

  useEffect(() => {
    var arr = location.pathname.split('/')
    if (arr[1] === 'home') {
      dispatch(tabActions.setSelectedTab('home'))
    } else if (arr.length > 2) {
      dispatch(tabActions.setSelectedTab(arr[arr.length - 1]))
    }
  }, [location.pathname])


  const onClickTab = (event, tabKey) => {
    dispatch(tabActions.setSelectedTab(tabKey))
    switch (tabKey) {
      case "home":
        push(SITE_PATHS.HOME_PAGE_PATH);
        break;
      default:
        var fData = tabData.filter(data => data.projectId == tabKey)
        const { projectName, projectImage, projectId, roleId, roleName, } = fData[0]
        const { state } = fData[0].path
        push(`${fData[0].path.path}`, { projectName, projectImage, projectId, roleId, roleName, ...state });
        break;
    }
  }


  const closeTab = (e, tabIndex) => {
    e.preventDefault();
    let sFilter = { ...selectedTabFilter }
    let pageFilter = { ...pageTabFilter }
    let tabFilter = { ...selectedPageTabFilter }
    let Pid = tabData[tabIndex].projectId
    var removedTab = tabData.splice(tabIndex, 1);
    delete sFilter[Pid]
    delete pageFilter[Pid]
    delete tabFilter[Pid]
    dispatch(tabActions.setSelectedTabFilter({ ...sFilter }));
    dispatch(tabActions.setPageTabFilter({ ...pageFilter }));
    dispatch(tabActions.setProjectTab({ ...tabFilter }));
    dispatch(tabActions.setTabData([...tabData]));
    if (removedTab[0].projectId == selectedTab) {
      dispatch(tabActions.setSelectedTab("home"));
      push("/home");
    }
  }



  return (
    <header className="site-header bg-white d-flex">
      <Button className={selectedTab === 'home' ? "homeHandleSelect" : "homeHandleUnselect"} onClick={(event) => onClickTab(event, 'home')}>
        <img src={home} alt="home" className="home-icon-h" />
      </Button>
      <Tabs
        value={selectedTab === 'home' ? false : `${selectedTab}`}
        onChange={onClickTab}
        variant="scrollable"
        scrollButtons="auto"
        aria-label="scrollable force tabs example"
      >
        {/* <Tab value="home" title={<img src={home} alt="home" />}> </Tab> */}
        {tabData.map((tab, tabIndex) => {
          return (
            <Tab
              key={`tab-key-${tabIndex}`}
              // selectedTab={tab.id}
              // eventKey={tab.id}
              value={`${tab.projectId}`}
              label={<TabStyle ProjectImage={tab.projectImage} name={tab.projectName} selected={selectedTab == tab.projectId} onClose={(e) => closeTab(e, tabIndex)} />}
            />

          )
        }
        )}
      </Tabs>
      <div className="d-flex action-h-c">
        {selectedTab !== "home" && <>
          <div className="d-flex align-items-center Header_date"> {`${moment(time.toISOString()).format("DD MMM YYYY hh:mm:ss")} IST`}</div>
          {/* <NotificationCenter /> */}
        </>}
        {/* <NavLink className="profile-h border-left" 
        to={{
          pathname: SITE_PATHS.PROFILE_EDIT_PAGE_PATH,
          state: {
          },
        }}>
          {userData?
          userData?.userInfo?.UserImage
            ? <img className="name-badge-img" src={`data:image/png;base64,${userData?.userInfo?.UserImage}`} />
            : <div className="name-badge">{userData?.firstName[0] + (userData?.lastName ? userData?.lastName[0] : "")}</div>:<img className="name-badge-img" src={iconAdminProfile}/>}
        </NavLink> */}


        <li className="profile-h border-left"
        >
          <img className="" style={{ height: 20, width: 20 }} src={notification} alt="notification" />
          {userData ?
            userData?.userInfo?.UserImage
              ? <img className="name-badge-img" src={`data:image/png;base64,${userData?.userInfo?.UserImage}`} />
              : <div className="name-badge">{userData?.firstName[0] + (userData?.lastName ? userData?.lastName[0] : "")}</div> : <img className="name-badge-img" src={iconAdminProfile} />}
        </li>
      </div>
    </header>
  );
}

const prevent = (fn, defaultOnly) => {
  return (e, ...params) => {
    e && e.preventDefault()
    !defaultOnly && e && e.stopPropagation()
    fn(e, ...params)
  }
}



const TabStyle = ({ name, selected, onClose, ProjectImage }) => {
  // return <div className={`tab d-flex align-items-center ${selected ? 'selected-tab' : ''}`}>
  return <div className={`tab d-flex align-items-center ${selected ? 'MuiTab-textColorPrimary' : 'MuiTab-textColorPrimary1'}`}>
    {/* <div className="tab__logo d-flex justify-content-center align-items-center f-14">{sortName(name)}</div> */}
    <div className="tab__logo d-flex justify-content-center align-items-center f-14"><img src={ProjectImage === undefined || ProjectImage === '' ? Logo : `data:image/png;base64,${ProjectImage}`} className="project-card-image" /> </div>
    <div className="tab__text pl-2 f-14 fw-400">{name}</div>
    <div className="tab__close pl-2" onClick={prevent(onClose)}><img src={crossSm} alt="closeTab" /></div>
  </div>
}


export default SiteHeader;
