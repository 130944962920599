export const SET_TAB_DATA = 'SET_TAB_DATA';
export const SET_SELECTED_TAB = 'SET_SELECTED_TAB';
export const SET_SELECTED_TAB_FILTER = 'SET_SELECTED_TAB_FILTER';
export const SET_SELECTED_PAGE_PAGESIZE_FILTER = 'SET_SELECTED_PAGE_PAGESIZE_FILTER';
export const SET_PROJECT_SELECTED_TAB = 'SET_PROJECT_SELECTED_TAB';


export function setTabData(data) {
    return { type: SET_TAB_DATA, data };
}

export function setSelectedTab(data) {
    return { type: SET_SELECTED_TAB, data };
}

export function setSelectedTabFilter(data) {
    return { type: SET_SELECTED_TAB_FILTER, data };
}

export function setPageTabFilter(data) {
    return { type: SET_SELECTED_PAGE_PAGESIZE_FILTER, data };
}

export function setProjectTab(data) {
    return { type: SET_PROJECT_SELECTED_TAB, data };
}

const TabActions = {
    setTabData,
    setSelectedTab,
    setSelectedTabFilter,
    setPageTabFilter,
    setProjectTab,
};

export default TabActions;