
export const UPDATE_LOGIN_INPROGRESS_STATUS = "UPDATE_LOGIN_INPROGRESS_STATUS"


export function updateSplashScreenStatus(status,from) {
  return {type: UPDATE_LOGIN_INPROGRESS_STATUS , status}
}
 
const SplashScreenAction = {
  updateSplashScreenStatus
};

export default SplashScreenAction;
