export const SET_PROJECT_DATA = 'SET_PROJECT_DATA';


export function setProjectData(data) {
    return { type: SET_PROJECT_DATA, data };
}


const ProjectActions = {
    setProjectData,
};

export default ProjectActions;