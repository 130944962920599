
import Keycloak from "keycloak-js";
const keycloak = new Keycloak({
 url: process.env.REACT_APP_KEYCLAOK_URL,
 realm: "ZIGRAM",
 clientId: process.env.REACT_APP_KEYCLOAK_CLIENT_ID,
});

export default keycloak;




