import React from "react";
import { Route, Switch } from "react-router-dom";
import { SITE_PATHS } from "config";
import { asyncComponent } from "components/Routing";



const BlankLayoutRoutes = () => (
  <Switch>

  </Switch>
);

export default BlankLayoutRoutes;
