import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { SITE_PATHS } from "config";
import { asyncComponent } from "components/Routing";
import ProtectedRoute from "./ProtectedRoute";
import { get as lodashGet } from "lodash";
import { store } from "services/Redux";
import NotFound from "pages/NotFound";

const {
  HOME_PAGE_PATH,
  SUPER_ADMIN_PANEL_PAGE_PATH,
  DEVELOPERS_GUIDE_PAGE_PATH,
  ADD_PROJECT_PAGE_PATH,
  EDIT_PROJECT_PAGE_PATH,
  PROFILE_EDIT_PAGE_PATH,
  PROFILE_PAGE_PATH,
  LOADER_URL,
  SIGN_IN_PAGE_PATH,
  notfound,
  API_DOCS,
  TRACKER_PAGE_PATH,
  DASHBOARD,

  ADMIN_SETTING_CHECK,
  ADMIN_SETTING_FRAMEWORK,
  ADMIN_SETTING_USERS,
} = SITE_PATHS;

const Home = asyncComponent(() =>
  import("pages/Home").then((module) => module.default)
);

const AddProjectPage = asyncComponent(() =>
  import("pages/AddProjectPage").then((module) => module.default)
);
const EditProjectPage = asyncComponent(() =>
  import("pages/EditProjectPage").then((module) => module.default)
);

const ProfileEditPage = asyncComponent(() =>
  import("pages/ProfileEditPage").then((module) => module.default)
);
const ProfilePage = asyncComponent(() =>
  import("pages/ProfilePage").then((module) => module.default)
);
const CheckSetting = asyncComponent(() =>
  import("pages/ProjectSettingPage").then((module) => module.default)
);
function MainLayoutRoutes() {
  const storeData = store.getState();
  const userStoreRoleInfo = lodashGet(
    storeData,
    "user.userData.userInfo.MainRole.RoleName",
    ""
  );
  return (
    <Switch>

      <ProtectedRoute exact path={HOME_PAGE_PATH} component={Home} />
      {/* {userStoreRoleInfo === "Super Admin" ? (
        <ProtectedRoute
          exact
          path={SUPER_ADMIN_PANEL_PAGE_PATH}
          component={SuperAdminPanel}
        />
      ) : (
        ""
      )} */}
      {userStoreRoleInfo === "Super Admin" && (
        <ProtectedRoute
          exact
          path={ADD_PROJECT_PAGE_PATH}
          component={AddProjectPage}
        />
      )}
      <ProtectedRoute
        exact
        path={`${EDIT_PROJECT_PAGE_PATH}/:projectID`}
        component={EditProjectPage}
      />
      <ProtectedRoute exact path={PROFILE_PAGE_PATH} component={ProfilePage} />

      <ProtectedRoute
        exact
        path="/"
        render={() => <Redirect to={SIGN_IN_PAGE_PATH} />}
      />
  <ProtectedRoute
        exact
        path={PROFILE_EDIT_PAGE_PATH}
        component={ProfileEditPage}
      />
      <ProtectedRoute exact path={`${ADMIN_SETTING_CHECK}`} component={CheckSetting} />

      <ProtectedRoute exact path={`${notfound}`} component={NotFound} />
    </Switch>
  );
}

export default MainLayoutRoutes;
