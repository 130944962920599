import Api from "./ApiWrapper";
import {
  HIDE_PROJECTS_ENDPOINT,
  SHOW_HIDDEN_PROJECTS_ENDPOINT,
  USER_DATA_ENDPOINT,
  FORGOT_PASSWORD_ENDPOINT,
  UPDATE_USER_DATA_ENDPOINT,
  USER_PROJECTS_ENDPOINT,
  USER_ALLOCATION_ENDPOINT,
  USERS_LIST_ENDPOINT,
  ROLE_OPTIONS_ENDPOINT,
  USER_OPTIONS_BY_ROLE_ID,
  ADMIN_USERS_ROLE_MAPPING_ENDPOINT,
  ADMIN_ADD_USER_ENDPOINT,
  ADMIN_DECIDE_USER_ROLE_ENDPOINT,
  ADMIN_PROJECT_LIST_ENDPOINT,
  BATCHES_LIST,
  ADMIN_PROJECT_SCHEMA_ENDPOINT,
  ADMIN_SCHEMA_SAMPLE_ENDPOINT,
  ADD_PROJECT_ENDPOINT,
  FETCH_PROJECT_DETAILS_ENDPOINT,
  UPDATE_PROJECT_DETAILS_ENDPOINT,
  FETCH_LISTING_HISTORY_ENDPOINT,
  UPLOAD_ALLOCATION_ENDPOINT,
  USER_PROFILE_IMAGE_ENDPOINT,
  UPDATE_USER_PROFILE_IMAGE_ENDPOINT,
  REFRESH_TOKEN,
  CONFIRM_EMAIL,
  SSO_LOGIN_ENDPOINT,
  SSO_CHANGE_PASSWORD_ENDPOINT,
  RESET_FORGOT_PASSWORD_ENDPOINT,
  CALENDER_FILTER,
  CALENDER_FILTER_DELIVERY,
  ALLOCATE_USER,
  DOWNLOAD_FILE,
  REOPEN_CASE,
  FINAL_SUBMIT,
  API_DOCS,
  FETCH_DELIVERY_DATA,
  FETCH_QAS_DATA,
  GET_TRANSACTION_BY_AUTOID,
  FETCH_QAS_DATA_FILTER,
  PUT_RETRY_ALLOCATION,
  CASE_TRANSACTION_VIEW_DATA,
  INPROGRESS_CASE_VIEW_DATA,
  FAILED_CASE_VIEW_DATA,
  CASE_VIEW_DATA,
  CASE_VIEW_DETAIL,
  DELIVERY_VIEW_DATA,
  DELIVERY_CASE_VIEW_DATA,
  DELIVERY_CASE_DOWNLOAD,
  LIVE_SEARCH_ENDPOINT,
  LIVE_SEARCH_TABLE_DATA_ENDPOINT,
  WORKSPACE_FILTER,
  MASTER_SEARCH,
  QAS_FILTER,
  DELIVERY_FILTER,
  NOTIFICATION_HISTORY,
  UPDATE_NOTIFICATION_HISTORY,
  UPLOAD_TRIGGER_ENDPOINT,
  TRIGGER_ACTION_ENDPOINT,
  COUNTRY_LIST_ENDPOINT,
  RETRY_FAILURE_ALLOCATION_ENDPOINT,
  DOWNLOAD_ZIP_ENDPOINT,
  LIVE_SEARCH_RECORD_ENDPOINT,
  BIOVIEW_JSON,
  getViewFilterOptions,
  GET_SCREENING_DATE,
  FILTER_REPORT_API,
  DELETE_DATA_BY_BATCHID,
  GET_CHECKS_MAPPING,
  BIOVIEW_PDF_DOWNLOAD,
  SCRORING_TAB_GET_ENDPOINT,
  ADMIN_SETTING_POST_TAB_ENDPOINT,
  SANCTION_LIST_BY_CHECKNAME_ENDPOINT,
  SAVE_LIST_BY_PROJECTID_ENDPOINT,
  FILTER_LIST,
  NEWEDITVIEW,
  UPDATEBIOVIEWFORM,
  UPDATE_PASSWORD,
  GET_ENTITY_VIEW,
  GET_ENTITY_TYPES,
  GET_DOB_CONFIG_DATA,
  SAVE_DOB_CONFIG_DATA,
  GET_RULES,
  SAVE_RULES,
  GET_MY_RULES,
  CREATE_NEW_RULE,
  UPDATE_RULE_JSON,
  UPDATE_PROJECT_RULES,
  GET_ENUM_DATA,
  GET_ENTITY_DATA,
  GET_ENTITIES_BY_TRANSACTION_ID,
  GET_TRANSACTION_RESULTS_BY_TRANSACTION_ID,
  GET_ENTITIES_RESULTS_BY_ENTITIES_ID,
  GET_ENTITY_DETAIL_BY_ENTITy_ID,
  ENTITY_VIEW_DETAIL,
  GET_RULES_JSON,
  DOWNLOAD_EXCEL_BATCH_ENDPOINT,
  EXPORT_ALL_RULES,
  GET_USER_LIST,
  GET_AUDIT_LOGS,
  RE_RUN_CASE_ENDPOINT,
  UPDATE_RULE_STATUS,
  GET_DASHBOARD_TICKER,
  GET_DASHBOARD_OVERVIEW_GRAPHS,
  GET_DEFAULT_RULE_JSON,
  PEP_LIST_BY_CHECKNAME_ENDPOINT,
  GET_TAB_COUNTS,
  DELETE_RULE,
  TRIGGER_CASE_ENDPOINT,
  POST_QUETION_API,
  GET_CHAT_HISTORY,
  REVIEW_MESSAGE_DATA,
  DELETE_CASE_ENDPOINT,
  DELETE_SOURCE_ENDPOINT,
  CASE_CATEGORY_PROJECT_WISE,
  SAMPLE_BATCH_FILE,
  INITIATE_LIVE_SCAN_CASE,
  ADD_NEW_USER_PROJECT,
  ADD_NEW_SOURCE_URL,
  CHAT_SUGGESTION,
  CASE_ZIZI_INPROGRESS_VIEW_DATA,
  CASE_ZIZI_NOT_INITIATED_DATA,
  CASE_ZIZI_COMPLETED_DATA,
  CASE_TAB_COUNT_DATA,
  CASE_SELF_ALLOCATION_DATA,
  SELF_ALLOCATION_USER_ROLE,
  POST_SELF_ALLOCATION_USER_ROLE,
  SELF_ALLOCATION_USER_WITH_ROLE,
  COMMENT_FEEDBACK_CHAT,
  CASE_COMMENT_FEEDBACK_CHAT,
  SOURCE_COMMENT_FEEDBACK_CHAT,
  GET_CASE_SOURCE_COMMENT,
  GET_CASE_STATUS
} from "./ApiEndpoints";
import { API } from "config";
import axios from "axios";
import Authorization from "services/AuthorizationService";

//LOGIN
// function login(UserName, Password) {
//   const params = new URLSearchParams();
//   params.append('user_name', UserName);
//   params.append('user_secret',Password);
//   params.append('grant_type','client_credentials');
//   const body = {data:{
//     user_name:UserName,
//     user_secret:Password,
//     grant_type:"client_credentials"
//     }
//   };

//   return Api.post(`${SSO_LOGIN_ENDPOINT}`, params);
// }


function login(UserName, Password) {
  const params = new URLSearchParams();
  params.append('userName', UserName);
  params.append('password', Password);

  const body = {
    userName: UserName,
    password: Password,
  };

  return Api.post(`${SSO_LOGIN_ENDPOINT}`, body);
}



//To Get User Profile Details
function fetchAccountDetails(userID) {
  const queryParams = `${userID}`;
  return Api.get(`${USER_DATA_ENDPOINT}${queryParams}`);
}
//
//UPDATE PASSWORD
function updateToNewPassword(body) {
  return Api.post(`${UPDATE_PASSWORD}`, body);
}

// PROFILE PROJECT WITH PAGINATION
function userProjectsListWithPagination(pageSize, pageNumber) {
  const queryParams = `pageSize=${pageSize}&pageNumber=${pageNumber}`;
  return Api.get(`${GET_PROFILE_PROJECT}?${queryParams}`);
}
//ALL PROJECTS LIST
function getAllProjectsList() {
  return Api.get(`${GET_ALL_PROJECTS_LIST}?userID=1`);
}
//GET SETTINGS SET BY USER
function getSettingByUser(listingId) {
  return Api.get(`${GET_SETTINGS_SET_BY_USER}?listingId=${listingId}`);
}

//USER ALLOCATION ENDPOINT
function userAllocationData(ProjectId, UserId, RoleId) {
  const queryParams = `?ProjectId=${ProjectId}&UserId=${UserId}&RoleId=${RoleId}`;
  return Api.get(`${USER_ALLOCATION_ENDPOINT}${queryParams}`);
}

function allocateUser(body) {
  return Api.post(`${ALLOCATE_USER}`, body);
}

function calenderDateFilter(body) {
  return Api.post(`${CALENDER_FILTER}`, body);
}
//delivery date filter
function calenderDateFilterDelivery(body) {
  return Api.post(`${CALENDER_FILTER_DELIVERY}`, body);
}
//DOWNLOAD ZIP ENDPOINT
function deliveryZipFileDownload(body) {
  return Api.post(`${DOWNLOAD_ZIP_ENDPOINT}`, body);
}

//Download File
function downloadFile(body) {
  return Api.post(`${DOWNLOAD_FILE}`, body, { responseType: "blob" });
}

function changePasswordSSO(body) {
  return Api.post(`${SSO_CHANGE_PASSWORD_ENDPOINT}`, body);
}

function resetForgotPassword(body) {
  return Api.post(`${RESET_FORGOT_PASSWORD_ENDPOINT}`, body);
}

function updateAccountDetails(data) {
  return Api.put(UPDATE_USER_DATA_ENDPOINT, data);
}

function fetchUserProfileImage() {
  return Api.get(USER_PROFILE_IMAGE_ENDPOINT);
}

function updateUserProfileImage(data) {
  return Api.post(UPDATE_USER_PROFILE_IMAGE_ENDPOINT, data);
}

function forgotPassword(recoveryEmail) {
  return Api.get(`${FORGOT_PASSWORD_ENDPOINT}?userName=${recoveryEmail}`);
}

function refreshToken(token) {
  return Api.post(REFRESH_TOKEN, token);
}

function fetchConfirmEmail() {
  return Api.get(CONFIRM_EMAIL);
}
//HOME PAGE SHOW HIDE PROJECTS
function hideProjects(projectId, status) {
  return Api.put(`${HIDE_PROJECTS_ENDPOINT}?projectId=${projectId}&ishidden=${status}`);
}
function showHiddenProjects() {
  return Api.put(SHOW_HIDDEN_PROJECTS_ENDPOINT);
}
//---------------------*****FINNET REPORT PAGE*****-------------------------//
function downloadFinnetReport(Body) {
  return Api.post(DOWNLOAD_FINNET_REPORT, Body);
}
function downloadFinnetReportCSV(Body) {
  return Api.post(DOWNLOAD_FINNET_REPORT_FROM_HISTORY, Body);
}
function getFinnetReportHistory(projectId, userId, pageSize, pageNumber) {
  return Api.get(`${GET_FINNET_REPORT_HISTORY}?projectId=${projectId}&userId=${userId}&pageSize=${pageSize}&pageNumber=${pageNumber}`);
}
function getFinnetSerach(projectId, userId, pageSize, pageNumber, searchtext) {
  return Api.get(`${FINNET_SEARCH_ENDPOINT}?projectId=${projectId}&userId=${userId}&pageSize=${pageSize}&pageNumber=${pageNumber}&searchtext=${searchtext}`);
}
function editFinnetReport(projectId, userId, requestId) {
  return Api.get(`${EDIT_FINNET_REPORT_ENDPOINT}?projectId=${projectId}&userId=${userId}&requestId=${requestId}`);
}
//---------------------*****ADMIN PANEL PAGE*****-------------------------//

function RoleOptions(projectId, isWorkSpace) {
  return Api.get(`${ROLE_OPTIONS_ENDPOINT}?projectId=${projectId}`);
}
function BioViewJson(listingid, RoleId, RunId) {
  const queryParams = `?listingid=${listingid}&RoleId=${RoleId}&RunId=${RunId}`;
  return Api.get(`${BIOVIEW_JSON}${queryParams}`);
}
function AdminUserRoles(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(
    `${ADMIN_USERS_ROLE_MAPPING_ENDPOINT}?projectid=${queryParams}`
  );
}
function RoleAssignByAdmin(Body) {
  return Api.post(ADMIN_DECIDE_USER_ROLE_ENDPOINT, Body);
}

function fetchAdminProjects(userID) {
  const queryParams = `${userID}`;
  return Api.get(`${ADMIN_PROJECT_LIST_ENDPOINT}?userId=${queryParams}`);
}
function fetchBatchDetails(projectID) {
  return Api.get(`${BATCHES_LIST}?projectid=${projectID}`);
}
function DeleteRule(ID, projectId) {
  const queryParams = `?rule_cd=${ID}&projectId=${projectId}`;
  return Api.delete(`${DELETE_RULE}${queryParams}`);
}
function updateRuleStatus(ID, status, projectId) {
  const queryParams = `?ruleCd=${ID}&rulestatus=${status}&projectId=${projectId}`;
  return Api.put(`${UPDATE_RULE_STATUS}${queryParams}`);
}
function registerUser(registerData) {
  return Api.post(ADMIN_ADD_USER_ENDPOINT, registerData);
}

function projectSchema(data) {
  return Api.post(ADMIN_PROJECT_SCHEMA_ENDPOINT, data);
}
function fetchSchemaSample(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(`${ADMIN_SCHEMA_SAMPLE_ENDPOINT}?projectId=${queryParams}`);
}
function fetchScoringTabData(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(`${SCRORING_TAB_GET_ENDPOINT}?projectId=${queryParams}`);
}
function postScoringTabData(data) {
  return Api.put(ADMIN_SETTING_POST_TAB_ENDPOINT, data);
}
function fetchSupervisorsList(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(`${SUPERVISORS_LIST_END_POINT}?projectId=${queryParams}`);
}
//---------------------*****Library PAGE*****-------------------------//
function fetchPEPListByCheckName(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(`${PEP_LIST_BY_CHECKNAME_ENDPOINT}?projectId=${queryParams}`);
}
function fetchSanctionListByCheckName(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(`${SANCTION_LIST_BY_CHECKNAME_ENDPOINT}?projectId=${queryParams}`);
}
function filterLisT(checkName, TagName) {
  const queryParams = `${TagName}`;
  return Api.get(`${FILTER_LIST}?checkName=${checkName}&tagName=${queryParams}`);
}
function saveListByProjectID(data) {
  return Api.post(SAVE_LIST_BY_PROJECTID_ENDPOINT, data);
}

//---------------------*****ADD PROJECT PAGE*****-------------------------//
function addProject(registerData) {
  return Api.post(ADD_PROJECT_ENDPOINT, registerData);
}
//---------------------*****RE RUN CASE*****-------------------------//
function reRunCase(id, ProjectId) {
  const queryparams = `?transactionId=${id}&projectId=${ProjectId}`
  return Api.get(`${RE_RUN_CASE_ENDPOINT}${queryparams}`);
}

function triggerCase(projectID, id) {
  const queryparams = `?projectid=${projectID}&caseMasterId=${id}`
  return Api.post(`${TRIGGER_CASE_ENDPOINT}${queryparams}`);
}
//---------------------*****RE RUN CASE*****-------------------------//
function reRunCaseHistory(caseId) {
  const queryParams = `${caseId}`;
  return Api.get(`${RE_RUN_HISTORY_CASE_ENDPOINT}?caseId=${queryParams}`);
}


// ZIZI PROJECT

//USER PROJECTS LIST
function userProjectsDetails(UserID) {
  // const queryParams = `?pageNumber=${pageNumber}&pageSize=${pageSize}`;?applicationuserid=1
  const queryParams = `?applicationuserid=${UserID}`;
  return Api.get(`${USER_PROJECTS_ENDPOINT}${queryParams}`);
}

//--------------------******DELETE CASE AND SOURCE******-----------------//

function deleteCaseWorkspace(caseId) {
  return Api.get(`${DELETE_CASE_ENDPOINT}?caseid=${caseId}`);
}

function deleteSourceWorkspace(caseId) {
  return Api.get(`${DELETE_SOURCE_ENDPOINT}?sourceid=${caseId}`);
}

//-------------------******Live Scan Page******---------------------//

function caseCategoryProjectWise(projectId) {
  return Api.get(`${CASE_CATEGORY_PROJECT_WISE}?projectid=${projectId}`);
}

function initiateLiveScanCase(updatedData) {
  return Api.post(INITIATE_LIVE_SCAN_CASE, updatedData);
}

//------------------*******Add New Source******--------------------------//

function addNewSourceCaseManager(updatedData) {
  return Api.post(ADD_NEW_SOURCE_URL, updatedData);
}

//-------------------******Add User New Project******--------------------//

function addNewUProject(body) {
  return Api.postJson(ADD_NEW_USER_PROJECT, body);
}

// ------------------******Batch Sample File******------------------//

function batchSampleFile(projectId) {
  return Api.get(`${SAMPLE_BATCH_FILE}?projectId=${projectId}`);
}

//---------------------*****REVIEW MESSAGE*****-------------------------//
function reviewMessage(updatedData) {
  return Api.post(REVIEW_MESSAGE_DATA, updatedData);
}

//---------------------*****REVIEW MESSAGE*****-------------------------//
function commentChatMessage(updatedData) {
  return Api.post(COMMENT_FEEDBACK_CHAT, updatedData);
}

//---------------------*****CHAT FEEDBACK MESSAGE*****-------------------------//
function caseCommentChatMessage(updatedData) {
  return Api.post(CASE_COMMENT_FEEDBACK_CHAT, updatedData);
}

//---------------------*****SOURCE FEEDBACK MESSAGE*****-------------------------//
function sourceCommentChatMessage(updatedData) {
  return Api.post(SOURCE_COMMENT_FEEDBACK_CHAT, updatedData);
}

//---------------------*****SEND QUESTION*****-------------------------//
function sendQuetionApi(updatedData) {
  return Api.post(POST_QUETION_API, updatedData);
}
//UPLOAD ALLOCATION PAGE
function UploadAllocation(uploadData) {
  return Api.post(`${UPLOAD_ALLOCATION_ENDPOINT}`, uploadData);
}

//---------------------*****EDIT PROJECT PAGE*****-------------------------//

function fetchProjectDetails(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(`${FETCH_PROJECT_DETAILS_ENDPOINT}?projectId=${queryParams}`);
}
function updateProjectDetails(updatedData) {
  return Api.post(UPDATE_PROJECT_DETAILS_ENDPOINT, updatedData);
}

function fetchListingHistory(payload) {
  return Api.get(`${FETCH_LISTING_HISTORY_ENDPOINT}?projectId=${payload.ProjectId}`);
}

function batchStatusHistory() {
  return Api.get(`${BATCH_STATUS_LIST}`);
}

function batchUploadUserList(ProjectId) {
  return Api.get(`${BATCH_UPLOAD_USER_LIST}?projectId=${ProjectId}`);
}

function filterBatchSearch(body) {
  return Api.post(`${FILTER_BATCH_UPLOAD}`, body);
}
function performTriggerAction(triggerData) {
  const queryParams = `${TRIGGER_ACTION_ENDPOINT}?listinghistoryId=${triggerData}`;
  return Api.get(queryParams);
}
//UPLOAD PAGE
function fetchUsersData(projectId) {
  const queryParams = `${projectId}`;
  return Api.get(`${USERS_LIST_ENDPOINT}?projectId=${queryParams}`);
}
function UploadTriggerOptions() {
  return Api.get(UPLOAD_TRIGGER_ENDPOINT);
}
//LIVE SEARCH PAGE
function liveSearch(body) {
  return Api.post(`${LIVE_SEARCH_ENDPOINT}`, body);
}
function fetchLiveSearchTableData(projectId) {
  const queryParams = `?projectId=${projectId}`;
  return Api.get(`${LIVE_SEARCH_TABLE_DATA_ENDPOINT}${queryParams}`);
}
function fetchLiveSearchRecordData(
  ProjectId,
  RoleId,
  sizePerPage,
  page
) {
  const queryParams = `?ProjectId=${ProjectId}&RoleId=${RoleId}&pageSize=${sizePerPage}&pageNumber=${page}`;
  return Api.get(`${LIVE_SEARCH_RECORD_ENDPOINT}${queryParams}`);
}

function fetchCountryList() {
  return Api.get(COUNTRY_LIST_ENDPOINT);
}
//NOTIFICATION HISTORY ENDPOINT
function fetchNotificationHistory(UserId, projectId) {
  const queryParams = `?UserId=${UserId}&projectId=${projectId}`;
  return Api.get(`${NOTIFICATION_HISTORY}${queryParams}`);
}
function updateNotificationHistory(body) {
  return Api.post(`${UPDATE_NOTIFICATION_HISTORY}`, body);
}

function clearAllNotification(body) {
  return Api.post(`${CLEAR_ALL_NOTIFICATION}`, body);
}


//---------------------*****DISAMBIGUATION VIEW PAGE*****-------------------------//

function DisambiguationViewJSON(listingid, RoleId, runId) {
  const queryParams = `?listingid=${listingid}&RoleId=${RoleId}&RunId=${runId}`;
  return Api.get(`${DISAMBIGUATION_DATA_ENDPOINT}${queryParams}`);
}
function DisambiguationEntityPdf(listingid, UserId, runId) {
  const queryParams = `?listingId=${listingid}&UserId=${UserId}&RunId=${runId}&ReportType=detail`;
  return Api.get(`${DISAMBIGUATION_PDF_ENTITY_ENDPOINT}${queryParams}`);
}

function DisambiguationCasePdf(caseId, UserId, runId) {
  const queryParams = `?caseId=${caseId}&UserId=${UserId}&RunId=${runId}&ReportType=detail`;
  return Api.get(`${DISAMBIGUATION_PDF_CASE_ENDPOINT}${queryParams}`);
}

//BIO-VIEW PDF
function BioEntityPdf(listingid, UserId, runId) {
  const queryParams = `?listingId=${listingid}&UserId=${UserId}&RunId=${runId}&ReportType=detail`;
  return Api.get(`${BIO_PDF_ENTITY_ENDPOINT}${queryParams}`);
}

function BioCasePdf(caseId, UserId, runId) {
  const queryParams = `?caseId=${caseId}&UserId=${UserId}&RunId=${runId}&ReportType=detail`;
  return Api.get(`${BIO_PDF_CASE_ENDPOINT}${queryParams}`);
}
function BioCaseSummaryPdf(listingid, caseId, UserId, runId, stage) {
  const queryParams = `?listingId=${listingid}&caseId=${caseId}&UserId=${UserId}&RunId=${runId}&ReportType=summary&stage=${stage}`;
  return Api.get(`${BIO_PDF_CASE_SUMMARY_ENDPOINT}${queryParams}`);
}
function BioCaseHighLightsPdf(listingid, caseId, UserId, runId, stage) {
  const queryParams = `?listingId=${listingid}&caseId=${caseId}&UserId=${UserId}&RunId=${runId}&ReportType=highlight&stage=${stage}`;
  return Api.get(`${BIO_PDF_CASE_HIGHLIGHTS_ENDPOINT}${queryParams}`);
}
//assign and allocate user
function fetchAllUsersBasedRole(projectId, userId) {
  const queryParams = `${projectId}`;
  return Api.get(`${USER_OPTIONS_BY_ROLE_ID}?projectid=${queryParams}&roleid=${userId}`);
}

//reopen case
function reopenCase(body) {
  return Api.post(`${REOPEN_CASE}`, body);
}

//final submit
function finalSubmit(body) {
  return Api.post(`${FINAL_SUBMIT}`, body);
}
// delivered page data
function getDelivery(ProjectId, UserId, RoleId) {
  const queryParams = `?ProjectId=${ProjectId}&UserId=${UserId}&RoleId=${RoleId}`;
  return Api.get(`${FETCH_DELIVERY_DATA}${queryParams}`);
}

//QAS
function getQAS(projectId, sizePerPage, page) {
  const queryParams = `${projectId}?pageSize=${sizePerPage}&pageNumber=${page}`;
  return Api.get(`${FETCH_QAS_DATA}/${queryParams}`);
}

function getQASDateFilter(projectId, fromDate, toDate) {
  // const queryParams = `${projectId}${fromDate}${toDate}`;
  return Api.get(
    `${FETCH_QAS_DATA_FILTER}?projectId=${projectId}&fromDate=${fromDate}&toDate=${toDate}`
  );
}
//AUDIT LOGS
function getAuditLogs(payload) {
  return Api.post(
    `${GET_AUDIT_LOGS}`, payload
  );
}

//AUDIT USER LOGS
function getUserData(projectId) {
  return Api.get(
    `${GET_USER_LIST}?projectId=${projectId}`
  );
}

//AUDIT EVENT LOGS
function getAuditEventLogs() {
  return Api.get(
    `${GET_AUDIT_EVENT_DATA}`
  );
}

//AUDIT ROLE LOGS
function getAuditRoleLogs(projectId) {
  return Api.get(
    `${GET_AUDIT_ROLE_DATA}?projectId=${projectId}`
  );
}

//AUDIT SEARCH LOGS
function getAuditSearchLogs(body) {
  return Api.post(`${GET_AUDIT_SEARCH_DATA}`, body);
}

function forAuditRealTimeRecord(body) {
  return Api.post(`${FOR_AUDIT_REALTIME_RECORD}`, body);
}
//DOWNLOAD AUDIT LOGS
function downloadAuditLogs(body) {
  return Api.post(`${DOWNLOAD_AUDIT_LOGS}`, body);
}
//retry allocation
function retryAllocation(listingId) {
  const queryParams = `${listingId}`;
  return Api.put(`${PUT_RETRY_ALLOCATION}/${queryParams}`);
}
//ALL CASE VIEW PAGE
function fetchZiziAllPageData(payload) {
  return Api.get(`${CASE_TRANSACTION_VIEW_DATA}?projectId=${payload?.ProjectId}`);
}
//INPROGRESS CASE VIEW PAGE
function fetchZiziInProgerssData(payload) {
  return Api.get(`${CASE_ZIZI_INPROGRESS_VIEW_DATA}?projectId=${payload?.ProjectId}`);
}

//NOT INITIATED CASE VIEW PAGE
function fetchZiziNotInitiatedData(payload) {
  return Api.get(`${CASE_ZIZI_NOT_INITIATED_DATA}?projectId=${payload?.ProjectId}`);
}

//COMPLETED CASE VIEW PAGE
function fetchZiziCompletedData(payload) {
  return Api.get(`${CASE_ZIZI_COMPLETED_DATA}?projectId=${payload?.ProjectId}`);
}

//COMPLETED CASE VIEW PAGE
function fetchZiziSelfAllocationData(payload) {
  return Api.get(`${CASE_SELF_ALLOCATION_DATA}?projectId=${payload?.ProjectId}`);
}

//GET SELF ALLOCATION USER DETAIL
function fetchZiziAllocationUser(ProjectId) {
  return Api.get(`${SELF_ALLOCATION_USER_ROLE}`);
}

//POST SELF ALLOCATION USER DETAIL
function postZiziAllocationUser(body) {
  return Api.post(`${POST_SELF_ALLOCATION_USER_ROLE}`, body);
}

//POST SELF ALLOCATION USER ROLE DETAIL 
function getZiziAllocationUserWithRole(projectid, roleid) {
  const queryParams = `projectid=${projectid}&roleid=${roleid}`;
  return Api.get(`${SELF_ALLOCATION_USER_WITH_ROLE}?${queryParams}`);
}

//TAB COUNT FOR ALL VIEW PAGE
function fetchWorkspaceTabData(ProjectId) {
  return Api.get(`${CASE_TAB_COUNT_DATA}?projectId=${ProjectId}`);
}

//INPROGRESS CASE VIEW PAGE
function fetchInProgressCaseViewData(ProjectId, RoleId, sizePerPage, page) {
  const queryParams = `?ProjectId=${ProjectId}&RoleId=${RoleId}&pageSize=${sizePerPage}&pageNumber=${page}`;
  return Api.get(`${INPROGRESS_CASE_VIEW_DATA}${queryParams}`);
}

//FAILED CASES
function fetchFailedCaseViewData(ProjectId, RoleId, sizePerPage, page) {
  const queryParams = `?ProjectId=${ProjectId}&RoleId=${RoleId}&pageSize=${sizePerPage}&pageNumber=${page}`;
  return Api.get(`${FAILED_CASE_VIEW_DATA}${queryParams}`);
}

//CASE VIEW PAGE
function fetchCaseViewData(ProjectId, RoleId, sizePerPage, page) {
  const queryParams = `?ProjectId=${ProjectId}&RoleId=${RoleId}&pageSize=${sizePerPage}&pageNumber=${page}`;
  return Api.get(`${CASE_VIEW_DATA}${queryParams}`);
}
//ADMIN ALLOCATION VIEW PAGE
function fetchAdminAllocationCaseViewData(ProjectId, RoleId, sizePerPage, page) {
  const queryParams = `?projectId=${ProjectId}&roleId=${RoleId}&pageSize=${sizePerPage}&pageNumber=${page}`;
  return Api.get(`${ADMIN_ALLOCATION_PAGE}${queryParams}`);
}
function CaseViewDetail(payload) {
  return Api.post(`${CASE_VIEW_DETAIL}`, payload);
}
function EntityViewDetail(payload) {
  return Api.post(`${ENTITY_VIEW_DETAIL}`, payload);
}
function getEnumData(type, projectID) {
  const queryParams = `?enumType=${type}&projectid=${projectID}`
  return Api.get(`${GET_ENUM_DATA}${queryParams}`);
}
function getDasboardTicker(payload) {
  return Api.post(`${GET_DASHBOARD_TICKER}`, payload);
}
function getDasboardOverviewGraphs(payload) {
  return Api.post(`${GET_DASHBOARD_OVERVIEW_GRAPHS}`, payload);
}
function DeliveryViewDetail(ProjectId, RoleId, sizePerPage, page) {
  const queryParams = `?ProjectId=${ProjectId}&RoleId=${RoleId}&pageSize=${sizePerPage}&pageNumber=${page}`;
  return Api.get(`${DELIVERY_VIEW_DATA}${queryParams}`);
}

function DeliveryCaseViewDetail(UserId, ProjectId, RoleId, caseId) {
  const queryParams = `?UserId=${UserId}&ProjectId=${ProjectId}&RoleId=${RoleId}&caseId=${caseId}`;
  return Api.get(`${DELIVERY_CASE_VIEW_DATA}${queryParams}`);
}
function GetDeliveryCaseDownload(body) {
  return Api.post(`${DELIVERY_CASE_DOWNLOAD}`, body);
}

//WORKSPACE VIEW PAGE
function GetWorkspaceExcelDownload(body) {
  return Api.post(`${DOWNLOAD_EXCEL_WORKSPACE_ENDPOINT}`, body);
}

function GetWorkspaceTabCountAPI(projectId, roleId) {
  const queryParams = `?projectId=${projectId}&roleId=${roleId}`
  return Api.get(`${GET_WORKSPACE_TAB_COUNT_ENDPOINT}${queryParams}`)
}
function GetWorkspaceExcelSummary(body) {
  return Api.post(`${DOWNLOAD_EXCEL_SUMMARY_WORKSPACE_ENDPOINT}`, body);
}

function GetBatchExcelDownload(id, Name) {
  const queryParams = `?batchID=${id}&batchName=${Name}`
  return Api.get(`${DOWNLOAD_EXCEL_BATCH_ENDPOINT}${queryParams}`);
}
function GetBatchSummaryExcelDownload(body) {
  return Api.post(`${DOWNLOAD_EXCEL_SUMMARY_BATCH_ENDPOINT}`, body);
}
function GetBatchPDFsDownload(body) {
  return Api.post(`${BATCH_PDF_DOWNLOADS_ENDPOINT}`, body);
}
function GetWorkSpaceFilter(projectID, roleId) {
  const queryParams = `?projectId=${projectID}&roleId=${roleId}`;
  return Api.get(`${WORKSPACE_FILTER}${queryParams}`);
}

function GetQasFilter() {
  return Api.get(`${QAS_FILTER}`);
}

function GetDeliveryFilter() {
  return Api.get(`${DELIVERY_FILTER}`);
}

function fetchWorkspacePDF(body) {
  return Api.post(`${WORKSPACE_PDF_ENDPOINT}`, body);
}
//mater filter for all pages
function masterFilter(body) {
  return Api.post(`${MASTER_SEARCH}`, body);
}

function filterLiveSearch(body) {
  return Api.post(`${FILTER_LIVE_SEARCH}`, body);
}

function GetLiveScanFilter(projectID) {
  const queryParams = `?projectId=${projectID}`;
  return Api.get(`${GET_LIVE_SEARCH_FILTER}${queryParams}`);
}

//RETRY ALLOCATION ENDPOINT
function retryFailureAllocation(caseId, runId, batchId, projectID) {
  const queryParams = `?caseId=${caseId}&runId=${runId}&batchId=${encodeURIComponent(batchId)}&projectId=${projectID}`;
  return Api.get(`${RETRY_FAILURE_ALLOCATION_ENDPOINT}${queryParams}`);
}

function GetPdfBioView(caseId, UserId) {
  const queryParams = `?caseId=${caseId}&UserId=${UserId}`;
  return Api.get(`${BIOVIEW_PDF_DOWNLOAD}${queryParams}`);
}

function NewEditViewJson(listingid, RoleId, runId) {
  const queryParams = `?listingid=${listingid}&RoleId=${RoleId}&RunId=${runId}`;
  return Api.get(`${NEWEDITVIEW}${queryParams}`);
}

function postForm(body) {
  return Api.post(`${UPDATEBIOVIEWFORM}`, body);
}

function getDeliveryExcel(body) {
  return Api.post(`${DELIVERY_REPORT_DOWNLOAD}`, body);
}

function deleteOnCaseId(BatchId) {
  const queryParams = `?caseId=${BatchId}`;
  return Api.delete(`${DELETE_DATA_BY_CASEID}${queryParams}`);
}

function deleteOnBatchId(BatchId, projectID) {
  const queryParams = `?batchId=${BatchId}`;
  return Api.delete(`${DELETE_DATA_BY_BATCHID}${queryParams}`);
}

function downloadBatchFile(BatchId) {
  const queryParams = `?listingHistoryid=${BatchId}`;
  return Api.get(`${DOWNLOAD_LISTING_FILE}${queryParams}`, {}, 'arraybuffer');
}

function downloadSampleFile(pId) {
  const queryParams = `?projectId=${pId}`;
  return Api.get(`${DOWNLOAD_SAMPLE_FILE}${queryParams}`, {}, 'arraybuffer');
}
function ClientsName() {
  return Api.get(`${CLIENTS_NAME}`);
}
function Tracker(clientName, fromDate, toDate) {
  const queryParams = `?clientName=${clientName}&fromDate=${fromDate}&toDate=${toDate}`;
  return Api.get(`${TRACKER}${queryParams}`);
}
function MonitoringByCaseId(caseId, monitoringId) {
  const queryParams = `?caseId=${caseId}&monitoringId=${monitoringId}`;
  return Api.get(`${MONITORING_BY_CASEID}${queryParams}`);
}
function MonitoringByBatchId(batchId, monitoringId) {
  const queryParams = `?batchId=${encodeURIComponent(batchId)}&monitoringId=${monitoringId}`;
  return Api.get(`${MONITORING_BY_BATCHID}${queryParams}`);
}
function Monitoring(body) {
  return Api.post(`${MONITORING}`, body);
}
function EndMonitoring(monitoringId, caseId, batchId) {
  const queryParams = `?monitoringId=${monitoringId}&caseId=${caseId}&batchId=${encodeURIComponent(batchId)}`;
  return Api.delete(`${END_MONITORING}${queryParams}`);
}

function UpdateMonitoring(body) {
  return Api.put(`${UPDATE_MONITORING}`, body);
}

//CASE INFORMATION SIDE DRAWER
function GetCaseInfo(caseId) {
  const queryParams = `?caseId=${caseId}`;
  return Api.get(`${GET_CASE_INFO}${queryParams}`);
}

//ENTITY INFORMATION SIDE DRAWER
function GetEntityInfo(listingId) {
  const queryParams = `?listingId=${listingId}`;
  return Api.get(`${GET_ENTITY_INFO}${queryParams}`);
}
// referesh api for entity status 

function GetRefreshStatus(projectID) {
  return Api.get(`${REFRESH_STATUS}?projectId=${projectID}`)
}


function downloadBulkSampleFile(pId) {
  const queryParams = `?projectId=${pId}`;
  return Api.get(`${DOWNLOAD_BULK_SAMPLE_FILE}${queryParams}`, {}, "arraybuffer");
}

function GetWorkSpaceDeliveryPDFForSingleCase(caseId, runId, stage) {
  return Api.get(`${GET_WORKSPACE_DELIVERY_PDF_FOR_SINGLE_CASE}?caseId=${caseId}&runId=${runId}&stage=${stage}`)

}


function DisambiguationSingleAttachmentUpload(file) {
  return Api.post(`${DISAMBIGUATION_ATTACHMENT_UPLOAD}`, file)
}

function DisambiguationAttachmentDelete(data) {
  return Api.delete(`${DISAMBIGUATION_ATTACHMENT_DELETE}?${data}`)
}

////////////////////////////NEW UI APIS 


function projectUserListFilter(payload) {
  return Api.post(`${PROJECT_USER_LIST_FILTER}`, payload);
}

function getAllUserList(projectId, pageNumber, pageSize) {
  const queryParams = `?ProjectId=${projectId}&pageNumber=${pageNumber}&pageSize=${pageSize}`;
  return Api.get(`${GET_ALL_USER}${queryParams}`);
}

function getAllRoleList(roleId) {
  const queryParams = roleId ? `${GET_ROLE_LIST}?roleId=${roleId}` : `${GET_ROLE_LIST}`;
  return Api.get(queryParams);
}

// function reassignRole({ ProjectId, RoleId, UserId }) {
//   const queryParams = `?userId=${UserId}&RoleId=${RoleId}&projectId=${ProjectId}`;
//   return Api.put(`${REASSIGN_ROLE}${queryParams}`);
// }

function getScheduleData(projectId, userId) {
  const queryParams = `${GET_SCHEDULE_UPLOAD}?projectId=${projectId}&userId=${userId}`;
  return Api.get(queryParams);
}



function changeUserStatus(payload) {
  return Api.post(CHANGE_USER_STATUS, payload);
}

function removeUser(payload) {
  return Api.post(REMOVE_USER, payload);
}

function transferAndRemoveUser(userId, transferDataToUserId, projectId, applicationRoleId) {
  const queryParams = `?userId=${userId}&transferDataToUserId=${transferDataToUserId}&projectId=${projectId}&transferRoleId=${applicationRoleId}`;
  return Api.get(`${TRAMSFER_AND_REMOVE}${queryParams}`);
}

function getProfileDetails(userId) {
  return Api.get(`${GET_PROFILE_DETAILS}?userId=${userId}`)
}

function inviteNewUser(payload) {
  return Api.post(INVITE_NEW_USER, payload);
}

function getSearchUser(search, useId, projectId) {
  return Api.get(`${SEARCH_USER}?userId=${useId}&search=${search}&projectId=${projectId}`);
}

function inviteExistingUser(payload) {
  return Api.post(INVITE_EXISTING_USER, payload);
}

function userSignUp(payload) {
  return Api.post(`${USER_SIGN_UP}`, payload)
}

function userUploadSampleFormat(projectId) {
  return Api.get(`${USER_UPLOAD_SAMPLE_FORMAT}?projectId=${projectId}`, {}, 'arraybuffer');
}

function getUsersFileFromS3(projectId, fileName) {
  return Api.get(`${USER_FILE_S3}?projectId=${projectId}&fileName=${fileName}`);
}

function deleteUsersFile(payload) {
  return Api.post(DELETE_USER_FILE, payload);
}

function bulkUploadUser(payload) {
  return Api.post(`${BULK_UPLOAD_USER}`, payload)
}

function filterUploadHistory(payload) {
  return Api.post(FILTER_UPLOAD_HISTORY, payload);
}

function getUserUploadHistory(projectId, roleId, pageNumber, pageSize) {
  return Api.get(`${GET_UPLOAD_HISTORY}?projectId=${projectId}&roleId=${roleId}&pageNumber=${pageNumber}&pageSize=${pageSize}`);
}

function getDownloadFile(projectId, fileName) {
  return Api.get(`${USER_FILE_S3}?projectId=${projectId}&fileName=${fileName}`, {}, 'arraybuffer');
}

function getIsHiddenInfo(projectId) {
  return Api.get(`${GET_IS_HIDDEN}?projectId=${projectId}`);
}

function putHiddenInfo(projectId) {
  return Api.put(`${UPDATE_IS_HIDDEN}?projectId=${projectId}`);
}

function GetDisambiguationAttachmentApi(listingId, projectId, isPreview) {
  return Api.get(`${GET_DISAMBIGUATION_ATTACHMENT}?listingId=${listingId}&ProjectId=${projectId}&isPreview=${isPreview}`)
}

function GetReviewerCountApi(projectID) {
  return Api.get(`${GET_REVIEWER_COUNT}?projectId=${projectID}`)

}

function MarkCaseDeliveredApi(body) {
  return Api.post(`${MARK_DELIVERED_API}`, body)

}

function GetTransferUserList(projectID, applicationRoleId, selectedUserId) {
  return Api.get(`${GET_TRAMSFER_AND_REMOVE}?projectId=${projectID}&roleId=${applicationRoleId}&selectedUserId=${selectedUserId}`)

}

// Batch Schedule
function GetMonitoringScheduledData(batchId, monitoringId) {
  return Api.get(`${MONITORING_BATCH_WITH_ID}?batchId=${encodeURIComponent(batchId)}&monitoringId=${monitoringId}`)
}

function GetProjectCounts(userId, pageSize, pageNumber) {
  return Api.get(`${GET_PROJECT_COUNT}?userId=${userId}&pageNumber=${pageNumber}&pageSize=${pageSize}`)
}

// Filter Data Options 

function GetViewFilters(listingId) {
  return Api.get(`${getViewFilterOptions}?listingId=${listingId}`)
}
function GetScreeningDate(CaseId) {
  return Api.get(`${GET_SCREENING_DATE}?caseId=${CaseId}`)
}
function ReportFilter(body) {
  return Api.post(`${FILTER_REPORT_API}`, body);
}
function GetChecksMapping() {
  return Api.get(`${GET_CHECKS_MAPPING}`);
}


function SearchFinnetReport(query) {
  return Api.get(`${SEARCH_FINNET_REPORT}${query}`)
}


function geTabCounts(projectId, pageName) {
  return Api.get(`${GET_TAB_COUNTS}?projectId=${projectId}&page=${pageName}`);
}

//pdfs
function GetDetailedPdfHits(caseId) {
  return Api.get(`${GET_DETAILS_PDF_HIT}?caseId=${caseId}`);
}

function GetHighLightPdfHits(caseId) {
  return Api.get(`${GET_HIGHLLIGHT_PDF_HIT}?caseId=${caseId}`);
}
function getAPIDocs() {
  return Api.get(`${API_DOCS}`);
}
//CRR Pages API 
function fetchEntityViewData(ProjectId, sizePerPage, page) {
  const queryParams = `?ProjectId=${ProjectId}&pageSize=${sizePerPage}&pageNumber=${page}`;
  return Api.get(`${GET_ENTITY_VIEW}${queryParams}`);
}

function fetchEntityTypeOptions() {
  return Api.get(`${GET_ENTITY_TYPES}`);
}
function getDOBConfig(projectId) {
  return axios.get(`${API.DATA_FACADE_URL}${GET_DOB_CONFIG_DATA}?ProjectId=${projectId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + Authorization.getAccessToken(),
    }
  });
}

function postDOBConfig(payload) {
  return axios.post(`${API.DATA_FACADE_URL}${SAVE_DOB_CONFIG_DATA}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + Authorization.getAccessToken(),
    }
  });
}

function getRules(projectId) {
  return axios.get(`${API.DATA_FACADE_URL}${GET_RULES}?ProjectId=${projectId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + Authorization.getAccessToken(),
    }
  });
}


function postRules(payload) {
  return axios.post(`${API.DATA_FACADE_URL}${SAVE_RULES}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + Authorization.getAccessToken(),
    }
  });
}



function getMyRules(body) {
  return Api.post(`${GET_MY_RULES}`, body);
}
function createNewRule(body) {
  return Api.post(`${CREATE_NEW_RULE}`, body);
}
function updateRulesJson(body) {
  return Api.post(`${UPDATE_RULE_JSON}`, body);
}
function getRuleJSON(ruleId, projectId) {
  const queryParams = `?ruleCd=${ruleId}&projectId=${projectId}`;
  return Api.get(`${GET_RULES_JSON}${queryParams}`);
}
function getDefaultRuleJSON(type, projectId, category) {
  const queryParams = `?ruleType=${type}&category=${category}&projectid=${projectId}`;
  return Api.get(`${GET_DEFAULT_RULE_JSON}${queryParams}`);
}
function exportAllRules(payload) {
  return Api.post(`${EXPORT_ALL_RULES}`, payload);
}
function getEntityData(payload) {
  return Api.post(`${GET_ENTITY_DATA}`, payload);
}
function getEntitiesDataByTransactionId(payload) {
  return Api.post(`${GET_ENTITIES_BY_TRANSACTION_ID}`, payload);
}
function getTransactionResultsByTransactionID(payload) {
  return Api.post(`${GET_TRANSACTION_RESULTS_BY_TRANSACTION_ID}`, payload);
}
function getTransactionResultsByAutoID(payload) {
  return Api.post(`${GET_TRANSACTION_BY_AUTOID}`, payload);
}
function getEntitiesResultsByEntitiesID(payload) {
  return Api.post(`${GET_ENTITIES_RESULTS_BY_ENTITIES_ID}`, payload);
}
function getEntityDetailsByEntityID(payload) {
  return Api.post(`${GET_ENTITY_DETAIL_BY_ENTITy_ID}`, payload);
}
function updateProjectRule(payload) {
  return axios.post(`${API.DATA_FACADE_URL}${UPDATE_PROJECT_RULES}`, payload, {
    headers: {
      "Content-Type": "application/json",
      Authorization: "bearer " + Authorization.getAccessToken(),
    }
  });
}



//zizi chat history api 
function getChatHistory(projectId, casemasterId) {
  const queryParams = `?projectId=${projectId}&casemaster_id=${casemasterId}`;
  return Api.get(`${GET_CHAT_HISTORY}${queryParams}`)
}

function getCaseSourceCommentHistory(projectId, casemasterId) {
  const queryParams = `?projectid=${projectId}&caseid=${casemasterId}`;
  return Api.post(`${GET_CASE_SOURCE_COMMENT}${queryParams}`)
}

function getCaseStatusHistory(casemasterId) {
  const queryParams = `?caseid=${casemasterId}`;
  return Api.get(`${GET_CASE_STATUS}${queryParams}`)
}

function getChatSuggestion(projectId, casemasterId) {
  const queryParams = `?projectId=${projectId}&casemaster_id=${casemasterId}`;
  return Api.get(`${CHAT_SUGGESTION}${queryParams}`)
}

const AccountApi = {
  login,
  fetchAccountDetails,
  userProjectsDetails,
  userAllocationData,
  forgotPassword,
  updateAccountDetails,
  fetchUserProfileImage,
  updateUserProfileImage,
  //ADMIN PANEL
  RoleOptions,
  AdminUserRoles,
  registerUser,
  RoleAssignByAdmin,
  fetchAdminProjects,
  fetchBatchDetails,
  projectSchema,
  fetchSchemaSample,
  //ADD PROJECT
  addProject,
  //EDIT PROJECT
  fetchProjectDetails,
  updateProjectDetails,
  fetchListingHistory,
  UploadAllocation,
  refreshToken,
  fetchConfirmEmail,
  //liveSearch
  liveSearch,
  fetchLiveSearchTableData,
  //loginSSO,
  changePasswordSSO,
  resetForgotPassword,
  fetchUsersData,
  //Allocation Page
  calenderDateFilter,
  allocateUser,
  //download files
  downloadFile,
  //fetch all user based on role
  fetchAllUsersBasedRole,
  reopenCase,
  finalSubmit,
  getDelivery,
  getQAS,
  getQASDateFilter,
  retryAllocation,
  calenderDateFilterDelivery,
  CaseViewDetail,
  EntityViewDetail,
  getEnumData,
  getDasboardOverviewGraphs,
  getDasboardTicker,
  fetchCaseViewData,
  fetchAdminAllocationCaseViewData,
  DeliveryViewDetail,
  DeliveryCaseViewDetail,
  GetDeliveryCaseDownload,
  GetWorkSpaceFilter,
  masterFilter,
  GetQasFilter,
  GetDeliveryFilter,
  fetchNotificationHistory,
  updateNotificationHistory,
  UploadTriggerOptions,
  performTriggerAction,
  fetchCountryList,
  retryFailureAllocation,
  deliveryZipFileDownload,
  fetchLiveSearchRecordData,
  BioViewJson,
  GetPdfBioView,
  fetchScoringTabData,
  postScoringTabData,
  NewEditViewJson,
  postForm,
  DisambiguationViewJSON,
  DisambiguationEntityPdf,
  DisambiguationCasePdf,
  BioEntityPdf,
  BioCasePdf,
  BioCaseSummaryPdf,
  BioCaseHighLightsPdf,
  getDeliveryExcel,
  fetchSupervisorsList,
  fetchPEPListByCheckName,
  saveListByProjectID,
  filterLisT,
  deleteOnCaseId,
  deleteOnBatchId,
  downloadBatchFile,
  downloadSampleFile,
  reRunCase,
  reRunCaseHistory,
  ClientsName,
  Tracker,
  GetRefreshStatus,
  GetWorkspaceExcelDownload,
  GetWorkspaceTabCountAPI,
  GetWorkspaceExcelSummary,
  GetBatchExcelDownload,
  MonitoringByCaseId,
  MonitoringByBatchId,
  Monitoring,
  EndMonitoring,
  GetCaseInfo,
  downloadBulkSampleFile,
  GetEntityInfo,
  UpdateMonitoring,
  fetchWorkspacePDF,
  GetBatchPDFsDownload,
  GetBatchSummaryExcelDownload,
  updateToNewPassword,
  GetWorkSpaceDeliveryPDFForSingleCase,
  DisambiguationSingleAttachmentUpload,
  DisambiguationAttachmentDelete,
  getSettingByUser,
  getAllProjectsList,
  // fetchBulkUserUploadedList,
  getProfileDetails,
  projectUserListFilter,
  fetchSanctionListByCheckName,
  getAllUserList,
  getAllRoleList,
  // reassignRole,
  changeUserStatus,
  removeUser,
  transferAndRemoveUser,
  inviteNewUser,
  getSearchUser,
  inviteExistingUser,
  userSignUp,
  userUploadSampleFormat,
  getUsersFileFromS3,
  deleteUsersFile,
  DeleteRule,
  bulkUploadUser,
  filterUploadHistory,
  getUserUploadHistory,
  getDownloadFile,
  getAuditLogs,
  getUserData,
  downloadAuditLogs,
  hideProjects,
  showHiddenProjects,
  GetDisambiguationAttachmentApi,
  fetchZiziAllPageData,
  fetchZiziInProgerssData,
  fetchZiziNotInitiatedData,
  fetchZiziCompletedData,
  fetchWorkspaceTabData,
  fetchZiziSelfAllocationData,
  fetchZiziAllocationUser,
  postZiziAllocationUser,
  getZiziAllocationUserWithRole,
  getEntityData,
  getEntitiesDataByTransactionId,
  getTransactionResultsByTransactionID,
  getTransactionResultsByAutoID,
  getEntitiesResultsByEntitiesID,
  getEntityDetailsByEntityID,
  fetchInProgressCaseViewData,
  getIsHiddenInfo,
  putHiddenInfo,
  getScheduleData,
  getAuditEventLogs,
  getAuditRoleLogs,
  getAuditSearchLogs,
  downloadFinnetReport,
  getFinnetReportHistory,
  downloadFinnetReportCSV,
  getFinnetSerach,
  GetReviewerCountApi,
  MarkCaseDeliveredApi,
  GetMonitoringScheduledData,
  forAuditRealTimeRecord,
  editFinnetReport,
  GetTransferUserList,
  GetProjectCounts,
  GetViewFilters,
  GetScreeningDate,
  ReportFilter,
  GetChecksMapping,
  filterLiveSearch,
  GetLiveScanFilter,
  batchStatusHistory,
  batchUploadUserList,
  filterBatchSearch,
  forAuditRealTimeRecord,
  editFinnetReport,
  SearchFinnetReport,
  userProjectsListWithPagination,
  fetchFailedCaseViewData,
  clearAllNotification,
  geTabCounts,
  GetDetailedPdfHits,
  GetHighLightPdfHits,
  fetchEntityViewData,
  fetchEntityTypeOptions,
  getDOBConfig,
  postDOBConfig,
  getRules,
  postRules,
  getMyRules,
  createNewRule,
  updateRulesJson,
  getRuleJSON,
  getDefaultRuleJSON,
  exportAllRules,
  updateProjectRule,
  updateRuleStatus,
  getAPIDocs,
  triggerCase,
  sendQuetionApi,
  getChatHistory,
  getCaseSourceCommentHistory,
  getCaseStatusHistory,
  reviewMessage,
  commentChatMessage,
  caseCommentChatMessage,
  sourceCommentChatMessage,
  deleteCaseWorkspace,
  deleteSourceWorkspace,
  caseCategoryProjectWise,
  batchSampleFile,
  initiateLiveScanCase,
  addNewUProject,
  addNewSourceCaseManager,
  getChatSuggestion
};

export default AccountApi;
