import { SITE_PATHS } from "config";

export const MAIN_ROUTES = [
  SITE_PATHS.HOME_PAGE_PATH,
  SITE_PATHS.SUPER_ADMIN_PANEL_PAGE_PATH,
  SITE_PATHS.DEVELOPERS_GUIDE_PAGE_PATH,
  SITE_PATHS.ADD_PROJECT_PAGE_PATH,
  SITE_PATHS.EDIT_PROJECT_PAGE_PATH,
  SITE_PATHS.PROFILE_EDIT_PAGE_PATH,
  SITE_PATHS.PROFILE_PAGE_PATH,
  SITE_PATHS.LOADER_URL,
  SITE_PATHS.SIGN_IN_PAGE_PATH,
  SITE_PATHS.notfound,
  SITE_PATHS.API_DOCS,
  SITE_PATHS.TRACKER_PAGE_PATH,
  SITE_PATHS.DASHBOARD,
  SITE_PATHS.ADMIN_SETTING_CHECK,
  SITE_PATHS.ADMIN_SETTING_FRAMEWORK,
  SITE_PATHS.ADMIN_SETTING_USERS,
];
