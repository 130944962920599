import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";

export function PopupOverlay(props) {
  const { modal, toggle, body, title, className, id } = props;
  const [showHeader, setShowHeader] = useState(true);
  useEffect(() => {
    if (props.showHeader === 'no') {
      setShowHeader(false);
    }
  }, [])
  return (
    <Modal isOpen={modal} toggle={toggle} className={className} id={`${id ? id : "NewPopup"}`} centered={true}>
      {showHeader && <ModalHeader toggle={toggle}>{title}</ModalHeader>}
      <ModalBody>{body}</ModalBody>
    </Modal>
  );
}
