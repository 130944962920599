import "./NotificationStyles.scss";
import { useCallback, useEffect, useState } from "react";
import { AccountApi } from "services/Api";
import { get as lodashGet } from "lodash";
import { store } from "services/Redux";
import { useLocation } from "react-router-dom";
import notifyBell from "assets/images/icons/NotificationIcon.svg";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import SiteLoader from "components/SiteArt/Loader/Loader";
import { PopupOverlay } from "components/PopupNew";
import moment from "moment";
export default function NotificationCenter() {
  const location = useLocation()
  const projectID = location.state?.projectId;


  const [notifications, setNotifications] = useState([]);
  const [notificationsDateWise, setNotificationsDateWise] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [markreadLoader, setMarkreadLoader] = useState(false);
  const [clearAllPopup, setClearAllPopup] = useState(false);
  const [clearAllLoader, setClearAllLoader] = useState(false)
  let today = new Date()
  let yesterday = new Date(today)
  yesterday.setDate(today.getDate() - 1)

  //NOTIFICATION HISTORY
  const storeData = store.getState();
  const userIdStore = lodashGet(storeData, "user.userData.userInfo.UserId", "");
  const handleFetchNotificationHistory = useCallback(() => {
    AccountApi.fetchNotificationHistory(userIdStore, projectID).then((res) => {
      if (res.status === 200) {

        const result = Object.groupBy(res.data.Body, ({ Time }) => Time)
        // let dataDW = res.data.Body.reduce((accu, item) => {
        //   const itemDate = new Date(item.Time);
        //   if (itemDate === today) {
        //     accu?.today?.push(item)
        //   } else if (isSameDate(new Date(item.Time))) {
        //     accu?.yesterDay?.push(item)
        //   } else {
        //     if (!accu[itemDate]) {
        //       accu[itemDate] = []
        //     }
        //     accu[itemDate]?.push(item)
        //   }
        //   return accu;
        // }, { today: [], yesterday: [] })
        setNotificationsDateWise(result)
        setNotifications(res.data.Body);
      }
    });
  }, [projectID]);
  // useEffect(() => {
  //   if (projectID) {
  //     handleFetchNotificationHistory();
  //   }
  // }, [projectID]);

  const isSameDate = (d1, d2) => {
    const yester = new Date();
    yester.setDate(yester.getDate() -1)
    return moment(d1).format("DD MMM YYYY") === moment(yester).format("DD MMM YYYY")
  }

  useEffect(() => {
    const addNotifications = (event) => {
      setNotifications((_notifications) => [
        ..._notifications,
        event.notification,
      ]);
    };
    NotificationManager.addEventListener("notification", addNotifications);
    NotificationManager.addEventListener(
      "refresh",
      handleFetchNotificationHistory
    );
    return () => {
      NotificationManager.removeEventListener("notification", addNotifications);
      NotificationManager.removeEventListener(
        "refresh",
        handleFetchNotificationHistory
      );
    };
  }, [projectID]);

  const onNotificationClick = (id) => {
    let dbVa = notifications.filter((item) => item.Id === id)
    const newNotifications = notifications?.map((noti) =>
      noti.id === id ? { ...noti, unread: false } : noti
    );
    AccountApi.updateNotificationHistory(dbVa)
      .then((res) => {
        if (res.status === 200) {
          handleFetchNotificationHistory();
        }
        setMarkreadLoader(false);
      })
      .catch(() => {
        setMarkreadLoader(false);
      });
    setNotifications(newNotifications);
  };
  //on Click Clear All
  const handleDeleteNotifications = () => {
    let updatedNotifications = notifications.filter((item) => item.isActive);
    const updatedNotificationsArr = Object.values(updatedNotifications);
    setMarkreadLoader(true);
    AccountApi.updateNotificationHistory(updatedNotificationsArr)
      .then((res) => {
        if (res.status === 200) {
          handleFetchNotificationHistory();
        }
        setMarkreadLoader(false);
      })
      .catch(() => {
        setMarkreadLoader(false);
      });
    // message: "Do you want to clear all the notifications?",
  };


  const clearAllApi = () => {
    setClearAllLoader(true)
    AccountApi.clearAllNotification(notifications)
      .then((res) => {
        if (res.status === 200) {
          handleFetchNotificationHistory();
        }
        setClearAllLoader(false);
        setClearAllPopup(false)
      })
      .catch(() => {
        setClearAllLoader(false);
      });
  }



  return projectID ? <div className="notification-icon-container">
    {clearAllPopup && (
      <PopupOverlay
        className=""
        modal={true}
        title={"Clear Notification"}
        toggle={() => setClearAllPopup(!clearAllPopup)}
        body={
          <div>
            <div className="mt-1">{"Do you want to clear all the notifications?"}</div>
            <div className="d-flex justify-content-end mt-4">
              <button className="new-Input-Button uncopyable d-inline-flex align-items-center" onClick={clearAllApi}>{clearAllLoader ? <SiteLoader height={20} width={20} /> : "Yes"}</button>
              <button className="new-Input-Button uncopyable ml-2 d-inline-flex align-items-center" onClick={() => setClearAllPopup(!clearAllPopup)}>No</button>
            </div>
          </div>
        }
      />
    )}
    <BellIcon
      onClick={() => setIsOpen(!isOpen)}
      // count={notifications.filter((n) => n.unread).length}
      count={notifications?.filter(ntData => ntData.isActive).length}
    />
    {isOpen && (
      <div>
        <div
          className="notification-overlay"
          onClick={() => setIsOpen(false)}
        />
        <div className="notifications-container">
          <ul className="notification-wrapper">
            {notifications.length === 0 ? (
              <p className="text-center">No Notification</p>
            ) : (
              <li className="notifyClearButton f-20 fw-600 d-flex justify-content-between">
                <div>
                  Notification{" "}
                </div>
                <div className="">
                  <button className="btn f-14 fw-600 cursor" style={{ width: "58px" }} onClick={() => setClearAllPopup(true)}>
                    Clear All
                  </button>{" "}
                  <button className="btn f-14 fw-600 mr-3 cursor" style={{ width: "91px" }} disabled={notifications.filter(fData => fData.isActive).length === 0} onClick={handleDeleteNotifications}>
                    {markreadLoader ? <SiteLoader height={20} width={20} /> : " Mark as read"}
                  </button>{" "}
                </div>
              </li>
            )}
            {Object.entries(notificationsDateWise)?.length > 0 &&
              <>{Object.entries(notificationsDateWise)?.map(([notification, items], idx) => (
                <>{items.length > 0 && <div className="notification"><div className="f-14 fw-600 mb-1">{notification === moment(today).format("DD MMM YYYY") ? "Today" : isSameDate(notification) ? "Yesterday" : moment(notification).format("DD MMM YYYY")}</div>
                  {items.map((item) => (
                    <li
                      key={idx}
                      className={`d-flex pb-2`}
                      onClick={() => onNotificationClick(item.Id)}
                    >
                      <div>
                        <p className="notification-text">{item.Message}</p>
                        {/* <span className="text-muted">{item.Time}</span> */}
                      </div>

                      {item.unread && <div className="unread" />}
                      <div className="pl-1 dotFix">
                        {item.isActive && <div className="unread" />}
                      </div>
                    </li>

                  ))}
                </div>}
                </>
              ))}</>
            }
          </ul>
        </div>
      </div>
    )}
  </div>
    : <></>

}

class NotificationManagerClass extends EventTarget {
  sendNotification(text) {
    if (!text) return;
    const event = new Event("notification");
    event.notification = { id: Date.now(), text, unread: true };
    this.dispatchEvent(event);
  }
  refreshNotifications() {
    const refresh = new Event("refresh");
    this.dispatchEvent(refresh);
  }
}

export const NotificationManager = new NotificationManagerClass("notification");

const BellIcon = ({ onClick, count }) => (
  <div className="bell-icon-wrapper position-relative" onClick={onClick ? onClick : undefined}>
    <img src={notifyBell} alt="NotificationBell" className="notifyBell" />

    {count !== 0 && <div className={`bell-icon-badge f-10 ${count > 99?" plus99":" "}` }>{count > 99 ? <div>99+</div> : count }</div>}
  </div>
);
