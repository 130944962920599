const SITE_PATHS = {
  DEVELOPERS_GUIDE_PAGE_PATH: "/dev",
  ADMIN_PANEL_PAGE_PATH: "/AdminPanel",
  SUPER_ADMIN_PANEL_PAGE_PATH: "/SuperAdminPanel",
  HOME_PAGE_PATH: "/home",
  PROJECT_PAGE_PATH: "/workspace/projectId", //path will be appended by "/PROJECT_ID"
  SIGN_IN_PAGE_PATH: "/login",
  SIGN_UP_PAGE_PATH: "/SignUp",
  ADD_PROJECT_PAGE_PATH: "/AddProject",
  EDIT_PROJECT_PAGE_PATH: "/EditProject/projectId",
  UPLOAD_ALLOCATION_PAGE_PATH: "/upload-document",
  PREVIEW_BATCH_UPLOADED_FILE_PAGE_PATH: "/preview-file",
  DISCLAIMER_PAGE_PATH: "/disclaimer",
  PRIVACY_POLICY_PAGE_PATH: "/privacy-policy",
  LIBRARY_LIST_PAGE:"/library",
  PROFILE_EDIT_PAGE_PATH: "/profile/edit",
  PROFILE_PAGE_PATH: "/admin",
  UPLOAD_BATCH_PAGE:"/upload-batch",
  SCAN_FILE_PAGE:"/scan-file",
  DOCUMENT_VIEWER_PAGE_PATH: "/doc", //path will be appended by "/document_resource_id"
  LOADER_URL: "/verify",
  CHANGE_PASSWORD: "/reset-password",
  LANDING_PAGE: "/",
  FINNET_PAGE: "/report/finnet",
  //QUICK_SCAN: "/quick-scan/projectId",
  DASHBOARD: "/dashboard",
  DASHBOARDPROJECT:"/project-dashboard",
  LIVESEARCH_PAGE_PATH: "/liveSearch",
  AUDITLOGS_PAGE_PATH: "/report/audit-logs",
  notfound: "/page-not-found",
  BIOVIEW: "/bio-view",
  EDITVIEW: "/disambiguation-view",
  DISAMBIGUATION_PAGE_PATH: "/final-view",
  PROJECT_SETTING_PAGE_PATH: "/project-setting",
  APC_FRAMEWORK_PAGE_PATH: "/apc-framework",
  API_DOCS: "/api-docs",
  LIBRARY_PAGE_PATH: "/library",
  RERUN_HISTORY_PAGE_PATH: "/ReRunHistory",
  TRACKER_PAGE_PATH: "/Tracker",
  USER_MANAGEMENT_PAGE_PATH :"/user",
  PROFILE_VIEW_PAGE_PATH:"/profile-view",
  USER_BULK_UPLOAD_PAGE_PATH:"/user/bulk-upload",

  SETTING_CHECK : "/setting/scoring",
  SETTING_FRAMEWORK : "/setting/framework",
  SETTING_USERS: "/setting/users",
  SETTING_RISK_RATING:"/setting/risk-rating",

  ADMIN_SETTING_CHECK : "/admin-setting/checks",
  ADMIN_SETTING_FRAMEWORK : "/admin-setting/framework",
  ADMIN_SETTING_USERS: "/admin-setting/users",

  SETTING_ROLES : "/setting/roles",

  AUDIT_LOG_DETAIL_PAGE:"/audit-log-detail",
  HISTORY_PAGE:"/history",
  ENTITY_VIEW_PAGE:"/entity-view",

  ENTITY_MANAGEMENT_PAGE:"/workspace/entity",
  CASE_MANAGEMENT:"/transactions",
  RULE_BASED_ENGINE: "/rule-engine/rule-library",
  RULES_DETAILS_PAGE:"/rule-details",
  AUDIT_LOGS_PAGE:"/audit-logs",
  NEW_RULE_PAGE:"/new-rule",
  VIEW_RULE_PAGE:"/view-rule",
  REPORTING_PAGE_PATH:"/reporting",
  ENTITY_VIEW_PAGE_PATH:"/entity-view",
  KEYCLOAK_AUTHENTICATE_PAGE : "/authentication"
};

//
export default SITE_PATHS;
