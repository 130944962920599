import { useSelector, useDispatch } from "react-redux";
import { tabActions } from "services/Redux";



export default function useSaveURL(projectId) {
    const tabData = useSelector((state) => state.tabs.tabData);
    const dispatch = useDispatch();

    const savePath = (path, state = null) => {
        var tabsInfo = [...tabData];
        for (const tab of tabsInfo) {
            if (tab.projectId == projectId) {
            tab.path = { path, state };
                break;
            }
        }
        dispatch(tabActions.setTabData(tabsInfo));
    }

    return savePath;
}
