import React from "react";
import { Router } from "react-router-dom";
import { SiteRoutes } from "routes";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { SITE_MESSAGES, SITE_SETTINGS } from "config";
import MomentUtils from "@date-io/moment";
// import keycloak from "config/Keycloak";
// import { ReactKeycloakProvider } from "@react-keycloak/web";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "services/Redux";

import History from "services/History";
// import { getAndSetClicks } from './utils';

import "styles/main.scss";
import keycloak from "config/Keycloak";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import UserIdeal from "components/UserIdeal/UserIdeal";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef()
    // if (SITE_SETTINGS.DEVELOPER_MODE) {
    //   document.addEventListener("contextmenu", event => {
    //     event.preventDefault();
    //     alert(SITE_MESSAGES.RIGHT_CLICK_DISABLED_MESSAGE);
    //   });
    // }
  }
  componentDidMount() {
    window.history.scrollRestoration = 'manual'
  }
  render() {
    return (
      <div className="App uncopyable" ref={(ref) => this._div = ref}>
        <ReactKeycloakProvider authClient={keycloak}>
          <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <UserIdeal>
                  <Router history={History}>
                    <SiteRoutes />
                  </Router>
                </UserIdeal>
              </MuiPickersUtilsProvider>
            </PersistGate>
          </Provider>
        </ReactKeycloakProvider>
      </div>
    );
  }
}

export default App;
