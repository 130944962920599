import { UPDATE_VIEW } from "../Actions/HomeViewAction";



const INITIAL_STATE = {
    grid: false
};

function HomeViewReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case UPDATE_VIEW:
            return {
                ...state,
                grid: action.data,
            };

        default:
            return state;
    }
}

export default HomeViewReducer;
