import { SET_TAB_DATA, SET_SELECTED_TAB, SET_SELECTED_TAB_FILTER, SET_SELECTED_PAGE_PAGESIZE_FILTER, SET_PROJECT_SELECTED_TAB } from "../Actions/TabActions";

const INITIAL_STATE = {
    tabData: [],
    selectedTabFilter: [],
    pageTabFilter: [],
    selectedPageTabFilter: [],
    selectedTab: "home",
};

function TabReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_TAB_DATA:
            return {
                ...state,
                tabData: action.data,
            };
        case SET_SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.data,
            };
        case SET_SELECTED_TAB_FILTER:
            return {
                ...state,
                selectedTabFilter: action.data,
            };
        case SET_SELECTED_PAGE_PAGESIZE_FILTER:
            return {
                ...state,
                pageTabFilter: action.data,
            };
        case SET_PROJECT_SELECTED_TAB:
            return {
                ...state,
                selectedPageTabFilter: action.data,
            };
        default:
            return state;
    }
}

export default TabReducer;
