import { get as lodashGet } from "lodash";
import { AccountApi } from "services/Api";
import { store, userActions, tabActions, HomeViewAction, splashScreenActions } from "services/Redux";
import History from "services/History";
import { SITE_PATHS } from "config";
import Cookies from "js-cookie";

import toaster from "components/Toaster/Toaster";
import cogoToast from "cogo-toast";
import CaseManagerActions from "services/Redux/Actions/CaseManngerAction";
import keycloak from "config/Keycloak";
// import keycloak from "config/Keycloak";

function getAccessToken() {
  const token1 = Cookies.get("temp_id");
  window.sessionStorage.setItem("token", token1);
  if (!window.sessionStorage.getItem("token")) {
    logout();
  }
  return token1;
}

// function login(email, password) {
//   return AccountApi.login(email, password)
//     .then((res) => {
//       // Cookies.remove("remove_id", { domain: window.location.hostname });
//       // Cookies.remove("temp_id", { domain: window.location.hostname });
//       // Cookies.remove("u_id", { domain: window.location.hostname });

//       // Cookies.remove("remove_id", { domain: ".transactcomply.com" });
//       // Cookies.remove("temp_id", { domain: ".transactcomply.com" });
//       // Cookies.remove("u_id", { domain: ".transactcomply.com" });

//       // localStorage.clear();

//       if (res.status === 200) {
//         const userData = res.data;
//         store.dispatch(userActions.setUserData(userData.userInfo));
//         const token = lodashGet(userData.token, "access_token", null);
//         // const refreshToken = lodashGet(userData, "refresh_token", null);
//         var date = 365;
//         //*****////*****/

//         // Cookies.set("remove_id", refreshToken, { expires: date, domain: window.location.hostname });
//         Cookies.set("temp_id", token, { expires: date, domain: window.location.hostname });
//         // Cookies.set("u_id", userData.user_id, { expires: date, domain: window.location.hostname });


//         store.dispatch(splashScreenActions.updateSplashScreenStatus(true))
//         setTimeout(() => {
//           let returnUrl = window.sessionStorage.getItem("returnUrl");
//           if (returnUrl) {
//             History.push(returnUrl);
//           } else {
//             History.push(SITE_PATHS.HOME_PAGE_PATH);
//           }
//           store.dispatch(splashScreenActions.updateSplashScreenStatus(false))
//         }, [3000])
//       } else {
//         const { hide } = cogoToast.error("Incorrect username or password.", {
//           toastContainerID: 'NewToast', position: 'top-right', heading: 'Error', hideAfter: 5,
//           onClick: () => {
//             hide();
//           },
//         });
//         return res.data
//       }
//     })
//     .catch((err) => {
//       logout();
//     });
// }




function login(email, password) {
  return AccountApi.login(email, password)
    .then((res) => {
     if(res.status === 200) {
      let data = JSON.parse(res.data.token.info).userDetails;
      const userData  = {
        firstName:data?.firstname,
        lastName :data?.lastname,
        userName: data?.username,
        department:data?.department,
        designation:data?.designation,
        userId:data?.id
      }
      store.dispatch(userActions.setUserData(userData))
      const token = lodashGet(res.data.token, "accessToken", null);
      // const refreshToken = lodashGet(userData, "refresh_token", null);
      var date = 365;
      //*****////*****/

      // Cookies.set("remove_id", refreshToken, { expires: date, domain: window.location.hostname });
      Cookies.set("temp_id", token, { expires: date, domain: window.location.hostname });
      // Cookies.set("temp_id", `${userData?.firstName}-${userData?.lastName}`, { expires: date, domain: window.location.hostname });
      History.push(SITE_PATHS.HOME_PAGE_PATH)
     }
    })
    .catch((err) => {
      logout();
    });
}


function logout() {
  keycloak.init().then((res) => {
    window.sessionStorage.removeItem("token");
    keycloak.logout(
      {
        redirectUri:process.env.REACT_APP_KEYCLOAK_LOGOUT_URL
      }
    ).then(() => {
      // store.dispatch({ data: null, type: "USER_LOGOUT" });
      // clearUserData();
    }).catch((err)=>{
      console.log(err)
      const { hide } = cogoToast.error("Something went wrong, Please try again", {
        toastContainerID: 'NewToast', position: 'top-right', heading: 'Error', hideAfter: 5,
        onClick: () => {
          hide();
        },
      });

    })
  }).catch(()=>{
    console.log(err)
    const { hide } = cogoToast.error("Something went wrong, Please try again", {
      toastContainerID: 'NewToast', position: 'top-right', heading: 'Error', hideAfter: 5,
      onClick: () => {
        hide();
      },
    });

  }).finally(()=>{
    // store.dispatch({ data: null, type: "USER_LOGOUT" });
    // clearUserData()
  });
  //toaster.show({message: "You have logout successfully", intent: "success"});
  // History.push(SITE_PATHS.SIGN_IN_PAGE_PATH);
}

function clearUserData() {
  Cookies.remove("remove_id", { domain: window.location.hostname });
  Cookies.remove("temp_id", { domain: window.location.hostname });
  Cookies.remove("u_id", { domain: window.location.hostname });
  window.sessionStorage.removeItem('returnUrl');
  window.sessionStorage.removeItem('insight-type');
  window.sessionStorage.removeItem("token");
  window.sessionStorage.clear();
  window.localStorage.clear();
  //  store.dispatch(userActions.logout(null));
  store.dispatch({ data: null, type: "USER_LOGOUT" });
  store.dispatch(userActions.setUserData(null));
  store.dispatch(tabActions.setSelectedTab("home"));
  store.dispatch(tabActions.setTabData([]));
  store.dispatch(HomeViewAction.setNewView(false));
  store.dispatch(CaseManagerActions.setCaseData([]));
}


function SSOLoginFunc(res) {
  Cookies.remove("remove_id", { domain: window.location.hostname });
  Cookies.remove("temp_id", { domain: window.location.hostname });
  Cookies.remove("u_id", { domain: window.location.hostname });
  localStorage.clear();
  if (res.status === 200 && res.data  && res.data.token) {
    let data = JSON.parse(res.data.token.info).userDetails;
      const userData  = {
        firstName:data?.firstname,
        lastName :data?.lastname,
        userName: data?.username,
        department:data?.department,
        designation:data?.designation,
        userId:data?.id
      }
      store.dispatch(userActions.setUserData(userData))
      const token = lodashGet(res.data.token, "accessToken", null);
        // const refreshToken = lodashGet(userData, "refresh_token", null);
        var date = 365;
        //*****////*****/

        // Cookies.set("remove_id", refreshToken, { expires: date, domain: window.location.hostname });
        Cookies.set("temp_id", token, { expires: date, domain: window.location.hostname });
        // Cookies.set("u_id", userData.user_id, { expires: date, domain: window.location.hostname });

      // var date = 365;
      // Cookies.set("temp_id", `${userData?.firstName}-${userData?.lastName}`, { expires: date, domain: window.location.hostname });
      History.push(SITE_PATHS.HOME_PAGE_PATH)

  }
}

export { login, logout, getAccessToken, clearUserData ,SSOLoginFunc};
