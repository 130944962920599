import React from "react";
import SVG_IMAGES from "./svgImages";

function SvgIcon(props) {
  const colorClass = props.color ? " svg-icon--" + props.color : "";
  const sizeClass = props.size ? " svg-icon--" + props.size : "";

  return (
    <div
      className={`svg-icon${
        props.className ? " " + props.className : ""
      }${colorClass}${sizeClass}`}
      onClick={props.onClick}
      onBlur={props.onBlur}
    >
      <img
        className={`svg-icon__image${
          props.imageClassName ? " " + props.imageClassName : ""
        }`}
        src={SVG_IMAGES[props.icon]}
        alt={props.alt || props.icon || "svg icon"}
      />
    </div>
  );
}

export default SvgIcon;
