export const SET_CASEMANGER_DATA = "SET_CASEMANGER_DATA"

export function setCaseData(data){
  return {type:SET_CASEMANGER_DATA, data}
}
 
const CaseManagerActions = {
  setCaseData
};

export default CaseManagerActions;
