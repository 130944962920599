import {
   
    UPDATE_LOGIN_INPROGRESS_STATUS
  } from "../Actions/SplashScreenActions";
  
  
  const INITIAL_STATE = {
    loginInprogress:false
  };
  
  function SplashScreenReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
      case UPDATE_LOGIN_INPROGRESS_STATUS:
         return {
          ...state,
          loginInprogress:action.status
         }
      default:
        return state;
    }
  }
  
  export default SplashScreenReducer;
  