import Loader from "assets/images/ZiZiLoader.svg";
import SplashScreenIcon from "assets/images/SplashLoaderZizi.svg"
function SiteLoader({height=50, width=50}) {
  return (
    <object data={Loader} height={height} width={width}></object>
  );
}

function FullPageLoader(props) {
  return (
    <object data={Loader} height={50} width={50} className="MainLoaderCenterAlign"></object>

  );
}

function SplashScreen() {
  return (
    <div className="splash-screen">
      <object data={SplashScreenIcon} height={100} width={100} />
    </div>
  )
}

export default SiteLoader;
export { FullPageLoader, SplashScreen };
