import {
  SET_USER_DATA,
  SET_USER_PROFILE_PICTURE,
  SET_USER_PROFILE_CONSTANT,
  DESTROY_SESSION,
  UPDATE_LOGIN_INPROGRESS_STATUS,
  SET_COUNTRY_FLAGS
} from "../Actions/UserActions";

import { DEFAULT_USER_PROFILE_PICTURE } from "config";

const INITIAL_STATE = {
  userData: null,
  userProfilePicture: null,
  UserImage:null,
  userProfileConstant: DEFAULT_USER_PROFILE_PICTURE,
  loginInprogress:false,
  countryFlags:{}
};

function UserReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.data,
      };

    case SET_USER_PROFILE_PICTURE:
      return {
        ...state,
        userData: {
          ...state.userData,
          userInfo:{
            ...state.userData.userInfo,
            UserImage:action.data
          }

        }
      };
    case UPDATE_LOGIN_INPROGRESS_STATUS:
       return {
        ...state,
        loginInprogress:action.status
       }
    case SET_USER_PROFILE_CONSTANT:
      return {
        ...state,
        userProfileConstant: DEFAULT_USER_PROFILE_PICTURE,
      };
    case DESTROY_SESSION:
      return {
        state: undefined,
      };

      case SET_COUNTRY_FLAGS :
        return {
          ...state,
          countryFlags : {...action.data}
        };

    default:
      return state;
  }
}

export default UserReducer;
