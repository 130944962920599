export const SET_USER_DATA = "SET_USER_DATA";
export const SET_USER_PROFILE_PICTURE = "SET_USER_PROFILE_PICTURE";
export const SET_USER_PROFILE_CONSTANT = "SET_USER_PROFILE_CONSTANT";
export const DESTROY_SESSION = "DESTROY_SESSION";
export const UPDATE_LOGIN_INPROGRESS_STATUS = "UPDATE_LOGIN_INPROGRESS_STATUS"
export const SET_COUNTRY_FLAGS = "SET_COUNTRY_FLAGS"

export function setUserData(data) {
  return { type: SET_USER_DATA, data };
}

export function setUserProfilePicture(data) {
  return { type: SET_USER_PROFILE_PICTURE, data };
}

export function setUserProfileConstant(data) {
  return { type: SET_USER_PROFILE_CONSTANT, data };
}

export function logout() {
  return { type: DESTROY_SESSION };
}

export function updateLoginStatus(status,from) {
  return {type: UPDATE_LOGIN_INPROGRESS_STATUS , status}
}

export function setCountryFlags(data){
  return {type:SET_COUNTRY_FLAGS, data}
}
 
const UserActions = {
  setUserData,
  setUserProfilePicture,
  setUserProfileConstant,
  logout,
  updateLoginStatus,
  setCountryFlags
};

export default UserActions;
