import { useSelector, useDispatch } from "react-redux";
import { tabActions } from "services/Redux";
import { useHistory } from "react-router-dom";
import { STATE } from "config";




export default function usePathHistory(projectID) {
    const tabData = useSelector((state) => state.tabs.tabData);
    const dispatch = useDispatch();
    const { push } = useHistory();

    const savePathHistory = (path, data = null) => {
        var tabsInfo = [...tabData];
        for (const tab of tabsInfo) {
            if (tab.projectId == projectID) {
                if (!Array.isArray(tab.pathHistory)) tab.pathHistory = []
                tab.pathHistory.push({ path, data })
                break;
            }
        }
        dispatch(tabActions.setTabData(tabsInfo));
    }
    const goBackInner = () => {
        var tabsInfo = [...tabData];
        for (const tab of tabsInfo) {
            if (tab.projectId == projectID) {
                if (!Array.isArray(tab.pathHistory)) break;
                var pathValue = tab.pathHistory.pop();
                tab.path = {path:pathValue.path,state:{}};
                if (STATE) push({
                    pathname: pathValue.path,
                    state: pathValue.data
                });
                else push(pathValue);
                break;
            }
        }
        dispatch(tabActions.setTabData(tabsInfo));
    }

    const clearHistory = () => {
        var tabsInfo = [...tabData];
        for (const tab of tabsInfo) {
            if (tab.projectId == projectID) {
                if (!Array.isArray(tab.pathHistory)) break;
                tab.pathHistory = null;
                break;
            }
        }
        dispatch(tabActions.setTabData(tabsInfo));
    }

    return { savePathHistory, goBackInner, clearHistory };
}
