import { combineReducers } from 'redux';
import userReducer from './userReducer';
import HomeViewReducer from './HomeViewReducer';
import TabReducer from './tabReducer';
import splashScrenReducer from "./splashScreenReducer"
import CaseManagerReducer from './casemangerReducer';
import ProjectReducer from './projectReducer';


const appReducer = combineReducers({
    user: userReducer,
    homeView: HomeViewReducer,
    tabs: TabReducer,
    splash:splashScrenReducer,
    caseManager: CaseManagerReducer,
    project: ProjectReducer,
});

const rootReducer = (state, action) => {
    if (action.type === 'USER_LOGOUT') {
        localStorage.removeItem('persist:root')
        return appReducer(undefined, action)
    }

    return appReducer(state, action)
}

export default rootReducer;
