// ------------------| ACCOUNTS ENDPOINTS |------------------

//PRE-SCREENING APIs
export const SSO_LOGIN_ENDPOINT = "api/login";
export const USER_DATA_ENDPOINT = "api/UserDetails?UserId=";
export const UPDATE_USER_DATA_ENDPOINT = "api/UserDetails/user";
export const USER_ALLOCATION_ENDPOINT = "api/WorkspaceCasesForAdmin";
//PRE-SCREENING ADMIN APIs
export const USERS_LIST_ENDPOINT = "api/UserDetailsByProjectId";
export const ROLE_OPTIONS_ENDPOINT = "tm/GetRolesByProjectId";
export const USER_OPTIONS_BY_ROLE_ID="tm/GetUserByRoleId"
export const ADMIN_USERS_ROLE_MAPPING_ENDPOINT = "api/UsersRolesMapping";
export const ADMIN_ADD_USER_ENDPOINT = "api/SignUp";
export const BATCHES_LIST="tm/GetBatchDetails";
export const ADMIN_DECIDE_USER_ROLE_ENDPOINT = "api/MapUserToProject";
export const ADMIN_PROJECT_LIST_ENDPOINT = "api/ProjectDetails/Listing";
export const ADMIN_PROJECT_SCHEMA_ENDPOINT = "api/JsonSchema";
export const ADMIN_SCHEMA_SAMPLE_ENDPOINT = "api/GetJsonSchema";
export const SCRORING_TAB_GET_ENDPOINT = "tm/GetScreeningScores";
export const ADMIN_SETTING_POST_TAB_ENDPOINT = "tm/UpdateScreeningScores";
export const SUPERVISORS_LIST_END_POINT = "api/Supervisors";
//Library Page APIs
export const PEP_LIST_BY_CHECKNAME_ENDPOINT = "tm/GetPEPsListData?projectid";
export const SANCTION_LIST_BY_CHECKNAME_ENDPOINT = "tm/GetGlobalSanctionData?projectid";
export const SAVE_LIST_BY_PROJECTID_ENDPOINT = 'api/SaveListNameByProjectId';
export const FILTER_LIST = 'api/ListFilter';
//ADD PROJECT PAGE APIs
export const ADD_PROJECT_ENDPOINT = "api/Project";
//DOWNLOAD ZIP FILES APIs
export const DOWNLOAD_ZIP_ENDPOINT = "api/DownloadById";
//LIVE SEARCH PAGE APIs
export const LIVE_SEARCH_ENDPOINT = "api/StartLiveSearch";
export const LIVE_SEARCH_TABLE_DATA_ENDPOINT = "api/LiveSearchHistory";
export const COUNTRY_LIST_ENDPOINT = "api/CountryList";
export const LIVE_SEARCH_RECORD_ENDPOINT =
  "api/GetLiveSearchCaseDataWithChecks";

//EDIT PROJECT PAGE APIs
export const FETCH_PROJECT_DETAILS_ENDPOINT = "api/DataByProjectId";
export const UPDATE_PROJECT_DETAILS_ENDPOINT = "api/UpdateProject";
export const RETRY_FAILURE_ALLOCATION_ENDPOINT = "api/RetryCase";
export const DOWNLOAD_EXCEL_WORKSPACE_ENDPOINT = "api/DownloadExcelReport";
export const DOWNLOAD_EXCEL_SUMMARY_WORKSPACE_ENDPOINT = "api/DownloadSummaryExcelReport";
//Workspace count API 

export const GET_WORKSPACE_TAB_COUNT_ENDPOINT = "api/GetWorkSpaceTabCount";

//UPLOAD ALLOCATION PAGE APIs
export const BATCH_STATUS_LIST = "api/GetBatchStatusList";
export const BATCH_UPLOAD_USER_LIST = "api/GetBatchUploadUser";
export const FILTER_BATCH_UPLOAD = "api/FilterBatchData";
export const DOWNLOAD_EXCEL_BATCH_ENDPOINT = "tm/DownloadBatchFile";
export const DOWNLOAD_EXCEL_SUMMARY_BATCH_ENDPOINT = "api/DownloadsummaryExcelBatchReport ";
export const SSO_CHANGE_PASSWORD_ENDPOINT = "v2/user/change_password";

export const REGISTER_ENDPOINT = "v1/user/register";

export const USER_PROFILE_IMAGE_ENDPOINT = "v1/user/image";
export const UPDATE_USER_PROFILE_IMAGE_ENDPOINT = "v1/user/image";

export const FORGOT_PASSWORD_ENDPOINT = "api/ForgotPassword";
export const RESET_FORGOT_PASSWORD_ENDPOINT = "api/ChangePasswordWithCode";
export const UPLOAD_TRIGGER_ENDPOINT = "api/ListingTriggerOptions";
export const TRIGGER_ACTION_ENDPOINT = "api/TriggerListing";
export const BATCH_PDF_DOWNLOADS_ENDPOINT = "api/DownloadBatchPdfReport";

//NOTIFICATION HISTORY APIs
export const NOTIFICATION_HISTORY = "api/GetNotifications";
export const UPDATE_NOTIFICATION_HISTORY = "api/UpdateNotification";
export const CLEAR_ALL_NOTIFICATION = "api/ClearAll";

//DISAMBIGUATION VIEW PAGE APIs
export const DISAMBIGUATION_DATA_ENDPOINT = "api/GetDisambiguationview";
//DISAMBIGUATION VIEW  PDF DOWNLOAD APIs
export const DISAMBIGUATION_PDF_ENTITY_ENDPOINT =
  "api/DownloadDisambiguatedPDFEntity";
export const DISAMBIGUATION_PDF_CASE_ENDPOINT =
  "api/DownloadDisambiguatedPDFCase";
//WORKSPCACE PDF DOWNLOAD 
export const WORKSPACE_PDF_ENDPOINT =
  "api/DownloadPdfReport";

//BIO/PRE_REVIEW VIEW  PDF DOWNLOAD APIs
export const BIO_PDF_ENTITY_ENDPOINT = "api/DownloadPDFEntity";
export const BIO_PDF_CASE_ENDPOINT = "api/DownloadPDFCase";
export const BIO_PDF_CASE_SUMMARY_ENDPOINT = "api/DownloadPdfSummary";
export const BIO_PDF_CASE_HIGHLIGHTS_ENDPOINT = "api/DownloadHighlightPdf";
// ------------------| REFRESH & CONFIRM RMAIL ENDPOINTS |------------------

export const REFRESH_TOKEN = "v1/refresh";
export const CONFIRM_EMAIL = "v1/user/confirm_email";
//download file
export const DOWNLOAD_FILE = "api/DownloadById";
//Fiter calender Api
// export const CALENDER_FILTER = "api/WorkspaceCasesForAdmin/filter";
export const CALENDER_FILTER = "api/GetCaseDataFilter";

//delivery filter
// export const CALENDER_FILTER_DELIVERY = "api/DeliveryCases/filter";
export const CALENDER_FILTER_DELIVERY = "api/DeliveryCasesfilter";

//Allocate User Super Admin User
export const ALLOCATE_USER = "tm/AllocateTransactionToUser";

//reopen case
export const REOPEN_CASE = "api/ReopenCase";

//final sumbit
export const FINAL_SUBMIT = "api/SubmitProfilesById";

//Fetch delivery data
export const FETCH_DELIVERY_DATA = "api/DeliveryCases";

//Fetch QAS Data
export const FETCH_QAS_DATA = "api/GetQAS";

//Fetch QAS Data filter
export const FETCH_QAS_DATA_FILTER = "api/GetQASFiltered";

//put retry allocation
export const PUT_RETRY_ALLOCATION = "api/RetryAllocation";
// GET IN PROGRESS CASE DETAILS 
export const INPROGRESS_CASE_VIEW_DATA = "api/GetInProgressData";
// GET FAILED CASE DETAILS
export const FAILED_CASE_VIEW_DATA = "api/GetFailedData"
//GET DELIVERY CASE DETAILS
export const CASE_VIEW_DATA = "api/GetCaseData";
export const CASE_VIEW_DETAIL = "tm/GetTransactionByID";
export const ENTITY_VIEW_DETAIL= "tm/GetTransactionByEntityID";
export const DELIVERY_VIEW_DATA = "api/GetCasesWiseDelivery";
export const DELIVERY_CASE_VIEW_DATA = "api/GetCasesWiseData";
export const DELIVERY_CASE_DOWNLOAD = "api/DownloadPostSummaryReport";
export const GET_ENUM_DATA='tm/GetEnumData';
export const WORKSPACE_FILTER = "api/GetFilterOption";
export const GET_ENTITY_DATA = "tm/GetEntitiesByProjectId";
export const GET_ENTITIES_BY_TRANSACTION_ID='tm/GetEntitiesByTransactionID';
export const GET_TRANSACTION_RESULTS_BY_TRANSACTION_ID='tm/GetTransactionResultByTransactionID';
export const GET_ENTITIES_RESULTS_BY_ENTITIES_ID='tm/GetEntitiesOutcomeByEntityID'
export const GET_ENTITY_DETAIL_BY_ENTITy_ID='tm/GetEntityDetailsByEntityid'
export const QAS_FILTER = "api/GetQASStatus";
export const DELIVERY_FILTER = "api/GetDeliveryStatus";
export const GET_TRANSACTION_BY_AUTOID="tm/GetTransactionByAutoId";
// Batch Schedule
export const MONITORING_BATCH_WITH_ID = "api/MonitoringByBatchId";


export const ADMIN_ALLOCATION_PAGE = "api/GetCaseDataForAdminAllocation"
//master search

export const MASTER_SEARCH = "api/FilterDeliveryView";

// /FilterLiveSearchData
export const FILTER_LIVE_SEARCH = "api/FilterLiveSearchData";
export const GET_LIVE_SEARCH_FILTER = "api/GetLiveSearchUser";

//bioview json get

export const BIOVIEW_JSON = "api/GetBioviewData";

export const BIOVIEW_PDF_DOWNLOAD = "api/DownloadPDF";

export const NEWEDITVIEW = "api/GetNewEditForm";

export const UPDATEBIOVIEWFORM = "api/PostEditForm";


//Report filters API

export const getViewFilterOptions = "api/GetViewFilterDataOptions";
export const GET_SCREENING_DATE ="api/GetScreeningDate";
export const FILTER_REPORT_API ="api/ViewFilter";
export const GET_CHECKS_MAPPING="api/GetCheckOrdering";
// delivery report download excel
export const DELIVERY_REPORT_DOWNLOAD = "api/ReportDownload";

export const DELETE_DATA_BY_CASEID = "api/DeleteDataByCaseId";
export const DELETE_DATA_BY_BATCHID = "tm/DeleteBatchByBatchId";

export const DOWNLOAD_LISTING_FILE =
  "api/DownloadListingFileByListingHistoryId";
export const DOWNLOAD_SAMPLE_FILE = "api/SampleFormat";



//RE_RUN
export const RE_RUN_CASE_ENDPOINT = "tm/ReRunTransaction";


//CLIENTS NAME
export const CLIENTS_NAME = "api/GetClientsName"

//Tracker
export const TRACKER = "api/Tracker"


export const REFRESH_STATUS = "api/GetRefreshStatus"

export const MONITORING_BY_CASEID = "api/MonitoringByCaseId"

export const MONITORING_BY_BATCHID = "api/MonitoringByBatchId"

export const MONITORING = "api/Monitoring"

export const END_MONITORING = "api/EndMonitoring"

export const GET_CASE_INFO = "api/GetCaseInfo"

// ProfileProject

export const GET_PROFILE_PROJECT = "api/GetProjectDetailsForProfilePage"


export const DOWNLOAD_BULK_SAMPLE_FILE = "api/DownloadUserUploadSampleFile"
export const GET_ENTITY_INFO = "api/GetEntityInfo"
export const UPDATE_MONITORING = "api/UpdateMonitoring"

//UPDATE NEW PASSWORD
export const UPDATE_PASSWORD = "api/UpdatePassword"


export const GET_WORKSPACE_DELIVERY_PDF_FOR_SINGLE_CASE = "api/GetWorkSpaceDeliveryPdf"

export const DISAMBIGUATION_ATTACHMENT_UPLOAD = "api/DisambiguationAttachmentUpload"
export const DISAMBIGUATION_ATTACHMENT_DELETE = "api/DeleteAttachment"
export const GET_SETTINGS_SET_BY_USER = "api/GetEntitySettings"
export const GET_ALL_PROJECTS_LIST = "api/getProjectList"

//User Management 
export const GET_PROFILE_DETAILS = "api/GetUserDetails";

//new UI Apis
export const PROJECT_USER_LIST_FILTER = "api/UserFilter";
export const GET_ALL_USER = "api/GetUsers";
export const GET_ROLE_LIST = "api/GetRoles";
// export const REASSIGN_ROLE = "api/ReAssignRoles";
export const CHANGE_USER_STATUS = "api/ChangeUserStatus";
export const REMOVE_USER = "api/RemoveUser";
export const TRAMSFER_AND_REMOVE = "api/TransferAndRemoveUser";
export const GET_TRAMSFER_AND_REMOVE = "api/GetTransferUserList";
export const INVITE_NEW_USER = "api/InviteNewUser";
export const SEARCH_USER = "api/GetSearchUser";
export const INVITE_EXISTING_USER = "api/InviteExistingUser";
export const USER_SIGN_UP = "api/UserSignUp";
export const BULK_UPLOAD_USER = "api/BulkUploadUser";
export const USER_UPLOAD_SAMPLE_FORMAT = "api/UserUploadSampleFormat";
export const USER_FILE_S3 = "api/GetUsersFileFromS3";
export const DELETE_USER_FILE = "api/DeleteUsersFile";
export const FILTER_UPLOAD_HISTORY = "api/FilterUploadHistory";
export const GET_UPLOAD_HISTORY = "api/GetUserUploadHistory";
export const GET_IS_HIDDEN = "api/GetIsHiddenInfo";
export const UPDATE_IS_HIDDEN = "api/UpdateIsHiddenInfo";
export const GET_SCHEDULE_UPLOAD = "api/GetScheduleUserUploadHistory";

//AUDIT LOGS
export const GET_AUDIT_LOGS = "/tm/GetAuditLogs"
export const DOWNLOAD_AUDIT_LOGS = "api/DownloadAuditLogs"
export const GET_AUDIT_EVENT_DATA = "api/GetAuditLogEventData"
export const GET_AUDIT_ROLE_DATA = "api/GetRolesForAuditLogs"
export const GET_USER_LIST = "tm/GetUsersDetailsForFilters"
export const GET_AUDIT_SEARCH_DATA = "api/SearchAuditLogs"
export const FOR_AUDIT_REALTIME_RECORD = "api/InsertRealTimeAuditLogs"

//HOME PAGE HIDE AND SHOW PROJECTS
export const HIDE_PROJECTS_ENDPOINT = "tm/UpdateProjectHideStatusByprojectId";
export const SHOW_HIDDEN_PROJECTS_ENDPOINT = "tm/UnhideAllProjects";

export const GET_DISAMBIGUATION_ATTACHMENT = "api/GetDisambiguationAttachment"

//FINNET REPORT PAGE 
export const DOWNLOAD_FINNET_REPORT = "api/DownloadFinnetReport";
export const GET_FINNET_REPORT_HISTORY = "api/GetFinnetReportHistory";
export const DOWNLOAD_FINNET_REPORT_FROM_HISTORY = "api/DownloadFinnetReportByRequestId";
export const FINNET_SEARCH_ENDPOINT = "api/FilterFinnetReportHistory";
export const SEARCH_FINNET_REPORT = "api/SearchFinnetReport"

//REVIEW PAGE L2 L3 
export const GET_REVIEWER_COUNT = "api/ProjectReviewerCount"
export const MARK_DELIVERED_API = "api/MarkDelivered"
export const EDIT_FINNET_REPORT_ENDPOINT = "api/GetEditFinnetReportHistory";
export const GET_PROJECT_COUNT = "api/GetProjectCardCount";


//SCAN HISTORY PAGE TAB COUNTS
export const GET_TAB_COUNTS="tm/GetCounts";

//PDFS
export const GET_DETAILS_PDF_HIT ="api/GetDetailedPdfHitsCount";
export const GET_HIGHLLIGHT_PDF_HIT ="api/GetHighLightPdfHitsCount";


//CRR APIS
export const GET_ENTITY_VIEW ="api/GetEntityData";
export const GET_ENTITY_TYPES="api/GetEntityTypeOptions";
//rulebase 
export const GET_DOB_CONFIG_DATA ="GetProjectDobParameter";
export const SAVE_DOB_CONFIG_DATA ="UpdateProjectDobParameter";
export const GET_RULES ="GetRules";
export const SAVE_RULES ="SetRules";

export const GET_MY_RULES= "tm/GetAllRules";
export const UPDATE_PROJECT_RULES ="SetProjectRulesConfiguration";
export const GET_RULES_JSON="tm/GetRuleBuilderJSON";
export const GET_DEFAULT_RULE_JSON="tm/GetRuleBuilderJSONDefault";
export const CREATE_NEW_RULE="tm/CreateNewRule";
export const UPDATE_RULE_JSON="tm/UpdateRuleBuilderJSON";
export const EXPORT_ALL_RULES="tm/ExportAllRules";
export const UPDATE_RULE_STATUS ="tm/UpdateRuleStatus";
export const DELETE_RULE="tm/DeleteRuleByClientCd";
export const API_DOCS ="tm/ApiDOC";
export const GET_DASHBOARD_TICKER ="tm/GetDashboardTickerCounts"
export const GET_DASHBOARD_OVERVIEW_GRAPHS='tm/GetDashboardGraphCount'
export const RE_RUN_HISTORY_CASE_ENDPOINT = "api/GetCaseHistory"


//ZIZI
//----------------------*****Home Page*****--------------------------//
// project list api
export const USER_PROJECTS_ENDPOINT = "api/projectlist"

// add new project
export const ADD_NEW_USER_PROJECT = "api/addproject"

//----------------------*****Case Manager Page*****--------------------------//

//GET ALL CASE DETAILS
export const CASE_TRANSACTION_VIEW_DATA = "api/casemaster";

//GET IN PROGRESS CASE DETAILS
export const CASE_ZIZI_INPROGRESS_VIEW_DATA = "api/inprogresscasemaster";

//GET NOT INITIATED CASE DETAILS
export const CASE_ZIZI_NOT_INITIATED_DATA = "api/notinitiatedcasemaster";

//GET COMPLETED CASE DETAILS
export const CASE_ZIZI_COMPLETED_DATA = "api/completedcasemaster";

//GET TAB COUNT FOR ALL VIEW PAGE
export const CASE_TAB_COUNT_DATA = "api/workspacetabcount";

//GET SELF ALLOCATION CASE DETAILS
export const CASE_SELF_ALLOCATION_DATA = "api/selfallocatedcasemaster";

//GET SELF ALLOCATION USER DETAILS
export const SELF_ALLOCATION_USER_ROLE = "api/applicationrole";

//GET SELF ALLOCATION USER ROLE DETAILS
export const SELF_ALLOCATION_USER_WITH_ROLE = "api/userunderrole";

//POST SELF ALLOCATION USER DETAILS
export const POST_SELF_ALLOCATION_USER_ROLE = "api/allocate";

// Delete Case and Source
export const DELETE_CASE_ENDPOINT = "api/deletecase";
export const DELETE_SOURCE_ENDPOINT = "api/deletesource";

//Trigger
export const TRIGGER_CASE_ENDPOINT = "api/initiatecase";

// Add new source
export const ADD_NEW_SOURCE_URL = "api/addsourcefile";

//----------------------*****View Page*****--------------------------//
// Chat history api
export const GET_CHAT_HISTORY = "api/chathistory"

// SEND CHAT QUERY APIs
export const POST_QUETION_API = "api/chatquery";
export const REVIEW_MESSAGE_DATA = "api/chatresponsefeedback";
export const COMMENT_FEEDBACK_CHAT = "api/chatresponsecomment";
export const CASE_COMMENT_FEEDBACK_CHAT = "api/casefeedback";
export const SOURCE_COMMENT_FEEDBACK_CHAT = "api/addcourcecomment";
export const GET_CASE_SOURCE_COMMENT = "api/getsourcecomment";
export const GET_CASE_STATUS = "api/casestatus";



//----------------------*****Batch Upload Page*****--------------------------//
// Download Sample Batch File
export const SAMPLE_BATCH_FILE = "api/downloadbatchfile";

// Upload batch file
export const UPLOAD_ALLOCATION_ENDPOINT = "api/BatchFile";
export const FETCH_LISTING_HISTORY_ENDPOINT = "api/batchhistory";

//----------------------*****Live Scan Page*****--------------------------//
// Live Scan Initiate 
export const INITIATE_LIVE_SCAN_CASE = "api/caselivescan";
export const CASE_CATEGORY_PROJECT_WISE = "api/casecategoryprojectwise";
export const CHAT_SUGGESTION = "api/chatsuggestionqa";


