import { SET_PROJECT_DATA } from "../Actions/ProjectActions";

const INITIAL_STATE = {
    projectData: [],
};

function ProjectReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case SET_PROJECT_DATA:
            return {
                projectData: action.data,
            };
        default:
            return state;
    }
}

export default ProjectReducer;
