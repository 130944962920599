import React, { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer/dist/index.legacy.cjs.js'
import { PopupOverlay } from "components/PopupNew";
import { useSelector } from 'react-redux';
import { logout } from 'services/AuthorizationService/AuthorizationService';
import TimeOut from "assets/images/icons/TimeOut.svg";

const timeout = 604800_000
// const timeout = 300_000
const promptBeforeIdle = 20_000

export default function UserIdeal(props) {
    const userData = useSelector((state) => state?.user?.userData)
    const [state, setState] = useState('Active')
    const [remaining, setRemaining] = useState(10)
    const [open, setOpen] = useState(false)
    let interval

    useEffect(() => {
        return () => {
            clearInterval(interval)
        }
    })


    const onIdle = () => {
        setState('Idle')
        timeoutLogout()
    }

    const timeoutLogout = () => {
        setOpen(false)
        clearInterval(interval)

        localStorage.setItem("inactivityMessage", "You have been logged out due to inactivity")

        logout()


    }

    const onActive = () => {
        setState('Active')
        setOpen(false)
        clearInterval(interval)
    }

    const onPrompt = () => {
        setState('Prompted')
        setOpen(true)
        if (userData) {
            interval = setInterval(() => {
                setRemaining(Math.ceil(getRemainingTime() / 1000))
            }, 500)
        }
    }

    const { getRemainingTime, activate } = useIdleTimer({
        onIdle,
        onActive,
        onPrompt,
        timeout,
        promptBeforeIdle,
        crossTab: true,
        // leaderElection: true,
        syncTimers: 200,
        throttle: 500
    })

    const handleStillHere = () => {
        activate()
        setOpen(false);
    }
    // console.log(userData && open);
    return (
        <div>
            {(userData && open) && <PopupOverlay
                className="modalpositionScreenEntity mt-0imp ScreeningPopup"
                modal={true}
                title={"Session Timeout"}
                body={
                    <div id="settingConfirm " className="my-3">
                        <div className='d-flex flex-column align-items-center'>
                            <img className='mb-3' src={TimeOut} style={{ height: 80, width: 80 }} />
                            <div className="mt-1 fw-600">Your Online Session Will Expire in</div>
                            <div className="mt-1  f-24 fw-600 d-flex justify-content-center align-items-center my-4" style={{ height: 44, width: 92, backgroundColor: "#50D7ED", borderRadius: 3, color: "#ffffff" }}>{remaining}s</div>
                            <div className="mt-1">Please click “<b>Stay Signed In</b>” to keep working</div>
                            <div className="mt-1">or click “<b>Sign Out</b>” to end your session now.</div>
                        </div>
                        <div className="d-flex justify-content-end mt-4">
                            <button className="btn btn-primary mr-3" style={{ backgroundColor: "#F2FCFF", borderColor: "#F8F6FE", color: "#50D7ED" }} onClick={timeoutLogout}>Sign Out</button>
                            <button className="btn btn-primary" onClick={handleStillHere}>Stay Signed In</button>
                        </div>
                    </div>
                }
            />}
            {props.children}
        </div>
    )
}
