import React from "react";
import { MainLayoutRoutes } from "routes";
import { InnerSiteNavigationPanel, SiteFooter, SiteHeader } from "components/Layout";

const MainLayout = (props) => (
  <div className="main-layout">
    <SiteHeader/>
    <InnerSiteNavigationPanel main={true} location={props.location}/>
    <main className="main-layout__main">
      <MainLayoutRoutes />
    </main>
    <SiteFooter />
  </div>
);

export default MainLayout;
