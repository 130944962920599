import React from "react";
import imgLogo from "assets/images/logo/logo.png";


function AmlLogo(props) {
  return (
    <div
      className={`aml-logo__container${
        props.className ? " " + props.className : ""
      }`}
      onClick={() => {
        window.location.replace("");
      }}
    >
      <img
        className={`aml-logo__image${
          props.imageClassName ? " " + props.imageClassName : ""
        }`}
        src={imgLogo}
        alt="medeina"
      />
    </div>
  );
}

export default AmlLogo;
