import React from "react";

import { SiteFooter, InnerSiteNavigationPanel, SiteHeader } from "components/Layout";
import { InnerLayoutRoutes } from "routes";
const InnerLayout = (props) => (
  <div className="main-layout">
    <SiteHeader/>
    <InnerSiteNavigationPanel location={props.location} />
    <main className="main-layout__main">
      <InnerLayoutRoutes location={props.location}/>
    </main>
    <SiteFooter />
  </div>
);

export default InnerLayout;
