import React, { useEffect, useState } from "react";
import { OutTable } from "react-excel-renderer";
import "./showexcel.scss";
export default function ShowExcelPage(props) {
  const { rowData, colData } = JSON.parse(localStorage.getItem("excelPreview")) || {};

  return (
    <div div className="react-bootstrap-table " id="NewPageLayout">
      <OutTable
        data={rowData}
        columns={colData}
        tableClassName="ExcelTable2007 table-responsive   NewTable"
        tableHeaderRowClass="customBG"
      />
    </div>
  );
}
