import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { SITE_PATHS } from "config";
import { asyncComponent } from "components/Routing";
import ProtectedRoute from "./ProtectedRoute";
const {
  HOME_PAGE_PATH,
  PROJECT_PAGE_PATH,
  // ADD_PROJECT_PAGE_PATH,
  // EDIT_PROJECT_PAGE_PATH,
  // PROFILE_EDIT_PAGE_PATH,
  // DASHBOARDPROJECT,
  // PROFILE_PAGE_PATH,
  // LOADER_URL,
  // SIGN_IN_PAGE_PATH,
  SETTING_CHECK,
  UPLOAD_BATCH_PAGE,
  SCAN_FILE_PAGE,
  // BIOVIEW,
  UPLOAD_ALLOCATION_PAGE_PATH,
  DOCUMENT_VIEWER_PAGE_PATH,
  // RULE_BASED_ENGINE,
  // ENTITY_MANAGEMENT_PAGE,
  // RULES_DETAILS_PAGE,
  // NEW_RULE_PAGE,
  // VIEW_RULE_PAGE,
  // CASE_MANAGEMENT,
  // AUDIT_LOGS_PAGE,
  // REPORTING_PAGE_PATH,
  // ENTITY_VIEW_PAGE_PATH,
  // LIBRARY_LIST_PAGE,
  // EDITVIEW,
} = SITE_PATHS;


const Home = asyncComponent(() =>
  import("pages/Home").then((module) => module.default)
);
const DocumentViewerPage = asyncComponent(() =>
  import("pages/DocumentViewerPage").then((module) => module.default)
);
const Project = asyncComponent(() =>
  import("pages/ProjectHome").then((module) => module.default)
);
const UploadBatchPage = asyncComponent(() =>
  import("pages/UploadBatchPage").then((module) => module.default)
);

const ScanFilePage = asyncComponent(() =>
  import("pages/ScanFilePage").then((module) => module.default)
);

// const AddProjectPage = asyncComponent(() =>
//   import("pages/AddProjectPage").then((module) => module.default)
// );
// const EditProjectPage = asyncComponent(() =>
//   import("pages/EditProjectPage").then((module) => module.default)
// );
// const ProjectPage = asyncComponent(() =>
//   import("pages/Project").then((module) => module.default)
// );

// const ProfilePage = asyncComponent(() =>
//   import("pages/ProfilePage").then((module) => module.default)
// );
// const AuditLogsPage = asyncComponent(() =>
//   import("pages/AuditLogsPage").then((module) => module.default)
// );
const ProjectSettingPage = asyncComponent(() =>
  import("pages/ProjectSettingPage").then((module) => module.default)
);
const UploadAllocationPage = asyncComponent(() =>
  import("pages/UploadAllocationPage").then((module) => module.default)
);
// const LibraryPage = asyncComponent(() =>
//   import("pages/LibraryPage").then((module) => module.default)
// );
// const BioViewPage = asyncComponent(() =>
//   import("pages/BioViewNew").then((module) => module.default)
// );
// const ReportingPage = asyncComponent(() =>
//   import("pages/ReportingPage/ReportingPage").then((module) => module.default)
// );

// const RuleBasedPage = asyncComponent(() =>
//   import("pages/RuleBasedPage").then((module) => module.default)
// );
// const EntityViewPage = asyncComponent(() =>
//   import("pages/EntityPage").then((module) => module.default)
// );
// const EntityDetailsViewPage = asyncComponent(() =>
//   import("pages/EntityViewPage").then((module) => module.default)
// );
// const RuleDetailsPage = asyncComponent(() =>
//   import("pages/RulesDetailsPage").then((module) => module.default)
// );
// const NewRulePage = asyncComponent(() =>
//   import("pages/NewRulePage").then((module) => module.default)
// );
// const ViewRulePage = asyncComponent(() =>
//   import("pages/ViewRulePage").then((module) => module.default)
// );
// const DashboardPage = asyncComponent(() =>
//   import("pages/DashboardPage").then((module) => module.default)
// );
// const DisambiguationPage = asyncComponent(() =>
//   import("pages/DisambiguationPage").then((module) => module.default)
// );
function MainLayoutRoutes({ location }) {
  return (
    <Switch>

      <ProtectedRoute exact path={HOME_PAGE_PATH} component={Home} />
      <ProtectedRoute
        exact
        path={`${PROJECT_PAGE_PATH}/:projectID`}
        component={Project}
      />
       <ProtectedRoute
        exact
        path={`${SETTING_CHECK}/:projectID`}
        component={ProjectSettingPage}
      />
      {/* {location?.state?.roleName === "Project Admin" ? (
        <ProtectedRoute
          exact
          path={ADMIN_PANEL_PAGE_PATH}
          component={AdminPanel}
        />
      ) : (
        ""
      )} */}
      {/* <ProtectedRoute
        exact
        path={`${PROJECT_PAGE_PATH}/:projectID`}
        component={Project}
      />
          <ProtectedRoute
        exact
        path={`${ENTITY_MANAGEMENT_PAGE}/:projectID`}
        component={EntityViewPage}
      />
            <ProtectedRoute
        exact
        path={`${DASHBOARDPROJECT}/:projectID`}
        component={DashboardPage}
      />
      <ProtectedRoute
        exact
        path={ADD_PROJECT_PAGE_PATH}
        component={AddProjectPage}
      />
       <ProtectedRoute
        exact
        path={`${AUDIT_LOGS_PAGE}/:projectID`}
        component={AuditLogsPage}
      />
      <ProtectedRoute
        exact
        path={`${LIBRARY_LIST_PAGE}/:projectID`}
        component={LibraryPage}
      />
      <ProtectedRoute
        exact
        path={`${REPORTING_PAGE_PATH}/:projectID`}
        component={ReportingPage}
      />
      <ProtectedRoute
        exact
        path={`${EDIT_PROJECT_PAGE_PATH}/:projectID`}
        component={EditProjectPage}
      />
       <ProtectedRoute
        exact
        path={`${CASE_MANAGEMENT}/:projectID`}
        component={ProjectPage}
      />
      <ProtectedRoute
        exact
        path={`${PROFILE_PAGE_PATH}`}
        component={ProfilePage}
      />
      <ProtectedRoute
        exact
        path={`${SETTING_CHECK}/:projectID`}
        component={ProjectSettingPage}
      />
        <ProtectedRoute
        exact
        path={`${ENTITY_VIEW_PAGE_PATH}/:projectID`}
        component={EntityDetailsViewPage}
      />
    
      <ProtectedRoute
        exact
        path={`${BIOVIEW}/:projectID/:transactionID`}
        component={BioViewPage}
      />
        <ProtectedRoute
        exact
        path={`${EDITVIEW}/:projectID/:transactionID`}
        component={DisambiguationPage}
      /> */}
        <ProtectedRoute
        exact
        path={`${UPLOAD_ALLOCATION_PAGE_PATH}/:projectID`}
        component={UploadAllocationPage}
      />
         <ProtectedRoute
        exact
        path={`${UPLOAD_BATCH_PAGE}/:projectID`}
        component={UploadBatchPage}
      />
        <ProtectedRoute
        exact
        path={`${SCAN_FILE_PAGE}/:projectID`}
        component={ScanFilePage}
      />
       <ProtectedRoute
        exact
        path={`${DOCUMENT_VIEWER_PAGE_PATH}/:caseId/:statusId?/:projectID`}
        component={DocumentViewerPage}
      />
      {/* <ProtectedRoute
        exact
        path="/"
        render={() => <Redirect to={SIGN_IN_PAGE_PATH} />}
      /> */}
{/* <ProtectedRoute exact path={`${RULE_BASED_ENGINE}/:projectID`} component={RuleBasedPage} /> */}
{/* <ProtectedRoute exact path={`${RULES_DETAILS_PAGE}/:projectID`} component={RuleDetailsPage} /> */}
{/* <ProtectedRoute exact path={`${VIEW_RULE_PAGE}/:projectID`} component={ViewRulePage} /> */}
{/* <ProtectedRoute exact path={`${NEW_RULE_PAGE}/:projectID`} component={NewRulePage} /> */}
      <Redirect from="*" to="/page-not-found" />
    </Switch>
  );
}

export default MainLayoutRoutes;
