import iconHome from "assets/images/icons/icon-home.svg";
import iconApartment from "assets/images/icons/icon-apartment.svg";
import iconBriefcase from "assets/images/icons/icon-briefcase.svg";
import iconDashboard from "assets/images/icons/icon-dashboard.svg";
import iconFile from "assets/images/icons/icon-file.svg";
import iconGavel from "assets/images/icons/icon-gavel.svg";
import iconInfo from "assets/images/icons/icon-info.svg";
import iconNotifications from "assets/images/icons/icon-notifications.svg";
import iconSearch from "assets/images/icons/icon-search.svg";
import iconServer from "assets/images/icons/icon-server.svg";
import iconStarBox from "assets/images/icons/icon-star-box.svg";
import iconTriangle from "assets/images/icons/icon-triangle.svg";
import iconUserSettings from "assets/images/icons/icon-user-settings.svg";
import iconUser from "assets/images/icons/icon-user.svg";
import iconWorld from "assets/images/icons/icon-world.svg";
import iconPrison from "assets/images/icons/icon-prison.svg";
import iconAdminProfile from "assets/images/icons/icon-admin-profile.svg";
import iconEventsRound from "assets/images/icons/events-white-round.png";
import iconUpload from "assets/images/icons/cloud-computing.svg";
import iconStaging from "assets/images/icons/staging.svg";
import iconEdit from "assets/images/icons/edit.svg";
import iconEditEnable from "assets/images/icons/EditIconEnable.svg";
import iconEditDisable from "assets/images/icons/EditIconDisable.svg";
import iconDownload from "assets/images/icons/download.svg";
import iconAdminPanel from "assets/images/icons/adminPanel.svg";
import iconAddProject from "assets/images/icons/addProject.svg";
import iconLogout from "assets/images/icons/logout.svg";
import iconuploadAllocation from "assets/images/icons/uploadAllocation.svg";
import iconDeliver from "assets/images/icons/Final_V2.svg";
import quickScan from "assets/images/icons/quickScan.svg";
import dashboard from "assets/images/icons/dashboard.svg";
import iconLiveSearch from "assets/images/icons/LiveSearch.svg";
import cancel from "assets/images/icons/cancel.svg";
import setting from "assets/images/icons/setting.svg";
import framework from "assets/images/icons/framework.png";
import docsIcon from "assets/images/icons/docsIcon.svg";
import TrackerIcon from "assets/images/icons/TrackerIcon.svg";
import DashbooardIcon from "assets/images/icons/dashboardPage.svg";
import AudiLogsIcon from "assets/images/icons/AuditLogsIcon.svg";

import WorkspaceNew from "assets/images/icons/Workspace-new.svg";
import SettingsNew from "assets/images/icons/Settings-new.svg";
import DashboardNew from "assets/images/icons/Dashboard-new.svg";
import UserNew from "assets/images/icons/User-new.svg";
import LogoutNew from "assets/images/icons/Logout-new.svg";
import HomeNew from "assets/images/icons/Home-new.svg";
import ApiDoc from "assets/images/icons/ApiDoc.svg";
import TrackerNew from "assets/images/icons/Tracker-New.svg";

import ApcSettingIcon from "assets/images/icons/apcSettings1.svg";
import iconFinnet from "assets/images/icons/iconFinnet.svg";
import iconHistory from "assets/images/icons/ScanHistory.svg";
import iconEntityManagemtn from "assets/images/icons/EntityManagement.svg";
import TransactionIcon from "assets/images/icons/TransactionIcon.svg";
import RuleEngine from "assets/images/icons/RuleEngine.svg";
const SVG_IMAGES = {
  apartment: iconApartment,
  iconHome: iconHome,
  briefcase: iconBriefcase,
  dashboard: iconDashboard,
  file: iconFile,
  gavel: iconGavel,
  info: iconInfo,
  notifications: iconNotifications,
  search: iconSearch,
  server: iconServer,
  "star-box": iconStarBox,
  triangle: iconTriangle,
  "user-settings": iconUserSettings,
  user: iconUser,
  world: iconWorld,
  prison: iconPrison,
  "admin-profile": iconAdminProfile,
  "events-round": iconEventsRound,
  iconUpload,
  iconStaging,
  iconEdit,
  iconEditEnable,
  iconEditDisable,
  iconDownload,
  iconAdminPanel,
  iconAddProject,
  iconLogout,
  iconuploadAllocation,
  iconDeliver,
  quickScan,
  dashboard,
  iconLiveSearch,
  cancel,
  framework,
  setting,
  docsIcon,
  TrackerIcon,
  DashbooardIcon,
  AudiLogsIcon,

  WorkspaceNew,
  SettingsNew,
  DashboardNew,
  UserNew,
  LogoutNew,
  HomeNew,
  
  ApcSettingIcon,
  AudiLogsIcon,
  iconFinnet,
  TrackerNew,
  ApiDoc,
  iconHistory,
  iconEntityManagemtn,
  TransactionIcon,
  RuleEngine
};

export default SVG_IMAGES;
