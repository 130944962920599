import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SITE_PATHS } from 'config';
import moment from 'moment';
import PS_Logo from "../../../assets/images/icons/PSIO_Footer_Logo_Grey.svg";
class SiteFooter extends Component {
  render() {
    return (
      <footer className="site-footer">
        <div className="px-3 w-100">
          <div className="row mx-0 w-100">
            <div className="col-lg-6 text-center text-lg-left pl-0">
            <a className="footer-link pl-0" href={`${process.env.REACT_APP_LANDING_PAGE_URL}disclaimer`} target="_blank">Disclaimer</a>
          <a className="footer-link"  href={`${process.env.REACT_APP_LANDING_PAGE_URL}privacy-policy`} target="_blank">Privacy Policy</a>
            </div>
            <div className="col-6 text-center text-lg-right d-flex align-items-center justify-content-end pr-0">
              <div className="d-flex">
                {/* <img src={PS_Logo} className="FooterLogo" /> */}
                <small className="copyright-text ml-2">
                  © Copyright {moment().format("YYYY")} ZIZI
                </small>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default SiteFooter;
