import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { SITE_PATHS } from "config";
import { asyncComponent } from "components/Routing";

const { SIGN_IN_PAGE_PATH, SIGN_UP_PAGE_PATH, CHANGE_PASSWORD, LANDING_PAGE } =
  SITE_PATHS;

const LandingPage = asyncComponent(() =>
  import("pages/Landing").then((module) => module.default)
);

const AuthenticationPage = asyncComponent(() =>
  import("pages/AuthenticationPage").then((module) => module.default)
);

const SignInPage = asyncComponent(() =>
  import("pages/SignInPage").then((module) => module.default)
);

const AuthorizationLayout = () => (
  <Switch>
    <Route exact path="/" component={AuthenticationPage}/>
    <Redirect exact from={[
      SIGN_IN_PAGE_PATH,
      SIGN_UP_PAGE_PATH,
    ]} to={"/"}/>
    {/* <Route path={`${SIGN_IN_PAGE_PATH}`} component={SignInPage} /> */}
    {/* <Route path={LANDING_PAGE} component={LandingPage} /> */}
    {/* <Route path={SIGN_UP_PAGE_PATH} component={SignUpPage} /> */}
    {/* <Route exact path="/" component={AuthenticationPage}/> */}
    {/* <Redirect exact from={"/"} to={SIGN_IN_PAGE_PATH}/> */}
  </Switch>
);

export default AuthorizationLayout;
