import {
  SET_CASEMANGER_DATA
} from "../Actions/CaseManngerAction";

const INITIAL_STATE = {
caseData : []
};

function CaseManagerReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_CASEMANGER_DATA:
      return {
        ...state,
        caseData: action.data,
      };

    default:
      return state;
  }
}

export default CaseManagerReducer;
