export const SITE_SETTINGS = {
  DEVELOPER_MODE: true,
};

// export const API = {
//   // BASE_URL: "https://prodapiv2.prescreening.io/", // prod new UI V2
//    BASE_URL: "https://devcoreapi.prescreening.io/uiapi/", // dev new UI V2
//  // BASE_URL: "https://stagev2api.prescreening.io/", // staging new UI v2
// };



export const API =  {
  BASE_URL : process.env.REACT_APP_BACKEND_API_BASE_URL,
  DATA_FACADE_URL: process.env.REACT_APP_DATA_FACADE
}
