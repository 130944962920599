import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { SvgIcon } from "components/SiteArt";
import { SITE_PATHS } from "config";
import AuthorizationService from "services/AuthorizationService";
import newLogo from 'assets/images/logo/ZiZi_Icon_Square.png'
import { Accordion, Card, Row, Col } from "react-bootstrap";
import newLogo2 from 'assets/images/logo/Logo-new-2.svg'
import defaultProfilePic from "assets/images/icon 02.svg"

import { get as lodashGet } from "lodash";
import { store } from "services/Redux";

import Logo from "assets/images/icons/Zigram-profile.svg";

import { connect, useDispatch } from "react-redux";
import { clearUserData, logout } from "services/AuthorizationService/AuthorizationService";
import History from "services/History";
import useSaveURL from "utils/useSaveURL";
import usePathHistory from "utils/usePathHistory";
import openDpNavIcon from 'assets/images/icons/openDpNavIcon.svg'
// import { useKeycloak } from "@react-keycloak/web";

const {
  PROJECT_PAGE_PATH,
  CASE_MANAGEMENT,
  ENTITY_MANAGEMENT_PAGE,
  RULE_BASED_ENGINE,
  SETTING_CHECK,
  UPLOAD_ALLOCATION_PAGE_PATH,
  AUDIT_LOGS_PAGE,
  REPORTING_PAGE_PATH,
  API_DOCS
} = SITE_PATHS;

const USER_ROLES = {
  SUPER_ADMIN: "Super Admin",
  PROJECT_ADMIN: "admin",
  SUPER_VISIOR: "Supervisor",
  MAKER: "Reviewer",
  MAKER1: "Reviewer (Level 1)",
  MAKER2: "Reviewer (Level 2)",
  MAKER3: "Reviewer (Level 3)"
};

const QUICK_LINK = [
  // {
  //   title: "Profile",
  //   icon: <SvgIcon className="nav-link__icon" icon="UserNew" />,
  //   linkTo: `${PROFILE_EDIT_PAGE_PATH}`,
  // },
  {
    title: "Logout",
    icon: <SvgIcon className="nav-link__icon" icon="LogoutNew" />,
    linkTo: `/login`,
  },
]

const SIDEBAR_MAIN_NAV_OPTIONS = [
  // {
  //   title: "Home",
  //   icon: <SvgIcon className="nav-link__icon" icon="HomeNew" />,
  //   linkTo: HOME_PAGE_PATH,
  //   roles: Object.values(USER_ROLES),
  // },
  // {
  //   title: "API Docs",
  //   icon: <SvgIcon className="nav-link__icon" icon="ApiDoc" />,
  //   linkTo: API_DOCS,
  //   roles: Object.values(USER_ROLES),
  // },
  // {
  //   title: "Dashboard",
  //   icon: <SvgIcon className="nav-link__icon" icon="DashboardNew" />,
  //   linkTo: DASHBOARD,
  //   roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.PROJECT_ADMIN],
  // },
  // {
  //   title: "Settings",
  //   icon: <SvgIcon className="nav-link__icon" icon="SettingsNew" />,
  //   // linkTo: ADMIN_PANEL_PAGE_PATH,
  //   key: "1",
  //   roles: [USER_ROLES.SUPER_ADMIN],
  //   pathWith: "/admin-setting",
  //   subOption: [
  //     {
  //       title: "Scoring",
  //       linkTo: `${ADMIN_SETTING_CHECK}`,
  //     },
  //     {
  //       title: "Framework",
  //       linkTo: `${ADMIN_SETTING_FRAMEWORK}`,
  //     },
  //     {
  //       title: "User Management",
  //       linkTo: `${ADMIN_SETTING_USERS}`,
  //     }
  //   ]
  // },
  // {
  //   title: "Tracker",
  //   icon: <SvgIcon className="nav-link__icon" icon="TrackerNew" />,
  //   linkTo: TRACKER_PAGE_PATH,
  //   roles: [USER_ROLES.SUPER_ADMIN],
  // },
];

function AppSidebar(props) {
  let stateV = localStorage.getItem("state");
  let stateB = JSON.parse(stateV)
  // const { keycloak, initialized } = useKeycloak()
  const { projectImage, projectName, projectId, roleId, roleName, apcFrameworkId, } = props?.location?.state || stateB || {};
  const storeData = store.getState();
  const userStoreRoleInfo = lodashGet(
    storeData,
    "user.userData.userInfo.MainRole.RoleName",
    ""
  );
  const isFreeSSOuser = lodashGet(storeData, "user.userData.isFreeSsoUser", false);
  const userRoleInfo = props?.main ? userStoreRoleInfo : roleName;
  const SIDEBAR_NAV_OPTIONS = [
    // {
    //   title: "Workspace",
    //   key: "2",
    //   pathWith: "/workspace",
    //   icon: <SvgIcon className="nav-link__icon" icon="iconFinnet" />,
    //   roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.PROJECT_ADMIN],
    //   subOption: [
    //     {
    //       title: "Transaction",
    //       linkTo: `${PROJECT_PAGE_PATH}/${projectId}`,
    //     },
    //     {
    //       title: "Entity",
    //       linkTo: `${ENTITY_MANAGEMENT_PAGE}/${projectId}`,
    //     },
    //   ],
    // },
    {
      title: "Workspace",
      icon: <SvgIcon className="nav-link__icon" icon="WorkspaceNew" />,
      linkTo: `${PROJECT_PAGE_PATH}/${projectId}`,
      roles: Object.values(USER_ROLES),
    },
    // {
    //   title: "Scan History",
    //   icon: <SvgIcon className="nav-link__icon" icon="iconHistory" />,
    //   linkTo: `${HISTORY_PAGE}/${projectId}`,
    //   roles: [USER_ROLES.SUPER_ADMIN, USER_ROLES.PROJECT_ADMIN],
    // },
    // {
    //   title: "History",
    //   icon: <SvgIcon className="nav-link__icon" icon="DashboardNew" />,
    //   linkTo: `${UPLOAD_ALLOCATION_PAGE_PATH}/${projectId}`,
    //   // disable:true,
    //   roles: [ USER_ROLES.PROJECT_ADMIN],
    // },
    // {
    //   title: "Case Manager",
    //   icon: <SvgIcon className="nav-link__icon" icon="WorkspaceNew" />,
    //   linkTo: `${PROJECT_PAGE_PATH}/${projectId}`,
    //   roles: [ USER_ROLES.PROJECT_ADMIN],
    // },
    // {
    //   title: "Transactions",
    //   icon: <SvgIcon className="nav-link__icon" icon="TransactionIcon" />,
    //   linkTo: `${CASE_MANAGEMENT}/${projectId}`,
    //   roles: Object.values(USER_ROLES),
    // },
    // {
    //   title: "Entity Manager",
    //   icon: <SvgIcon className="nav-link__icon" icon="iconEntityManagemtn" />,
    //   linkTo: `${ENTITY_MANAGEMENT_PAGE}/${projectId}`,
    //   roles: [ USER_ROLES.PROJECT_ADMIN],
    // },
    // {
    //   title: "Rule Engine",
    //   icon: <SvgIcon className="nav-link__icon" icon="RuleEngine" />,
    //   // linkTo: ADMIN_PANEL_PAGE_PATH,
    //   key: "1",
    //   roles: [USER_ROLES.PROJECT_ADMIN],
    //   pathWith: "/rule-engine",
    //   subOption: [
    //     {
    //       title: "Rule Library",
    //       linkTo: `${RULE_BASED_ENGINE}/${projectId}`,
    //     },
    //   ],
    // },
    // {
    //   title: "Reporting",
    //   icon: <SvgIcon className="nav-link__icon" icon="iconFinnet" />,
    //   linkTo: `${REPORTING_PAGE_PATH}/${projectId}`,
    //   disable:true,
    //   roles: [ USER_ROLES.PROJECT_ADMIN],
    // },
    // {
    //   title: "Audit Logs",
    //   icon: <SvgIcon className="nav-link__icon" icon="AudiLogsIcon" />,
    //   linkTo: `${AUDIT_LOGS_PAGE}/${projectId}`,
    //   disable:true,
    //   roles: [ USER_ROLES.PROJECT_ADMIN],
    // },
    {
      title: "Settings",
      icon: <SvgIcon className="nav-link__icon" icon="SettingsNew" />,
      // linkTo: ADMIN_PANEL_PAGE_PATH,
      key: "2",
      roles: [USER_ROLES.PROJECT_ADMIN],
      pathWith: "/setting",
      subOption: [
        {
          title: "Scoring",
          linkTo: `${SETTING_CHECK}/${projectId}`,
        },
      ],
    },
  ];

  const { userProfilePicture, userProfileConstant } = props;

  const [isSidebarLocked, setIsSidebarLocked] = useState(false);
  const [Arrow, setArrow] = useState(false);
  const savePath = useSaveURL(projectId);
  const { clearHistory } = usePathHistory(projectId)
  const dispatch = useDispatch();



  const onLinkClick = (data) => {
    if (data.pathname === `/login`) {

    logout()
      
    } else {
      History.push(data)
    }
  }

  const onClickNev = (linkTo) => {
    // clearHistory();
    savePath(linkTo);
  }


  return (
    <div
      id="indder-nav"
      className={`site-navigation-panel uncopyable${isSidebarLocked ? " is-locked" : ""
        }`}
    >
      <div className="position-relative h-100">
        <div className="site-navigation-panel__logo-container d-flex align-items-center justify-content-center">
          <img src={newLogo} className="inner-site-brand-logo-img" />
          <div className="nav-link__comp-name  h-100  d-flex flex-row align-items-center  logo-white-clor " >
            {/* <span className="nav-link__divider mx-1"></span> */}
            <span className="ml-2" >
              ZIZI
            </span>
          </div>
        </div>
        <div className="company-container__open">
          <div className="d-flex">
            {window.location.pathname === "/home" ? <img
              className="inner-site-brand-logo-img h-100 bg-white"
              src={Logo}
              alt=""
            /> : <img
              className="inner-site-brand-logo-img h-100 bg-white"
              src={projectImage ? `data:image/png;base64,${projectImage}` : Logo}
              alt=""
            />}
          </div>

          {window.location.pathname === "/home" ?
            <div title={projectName} className="mt-2 h-100  d-flex flex-row align-items-center justify-content-center" >
              <span className="align-items-center f-16 nav-link__projectName  d-flex" title={projectName} >
                <div className=" poweredBy">powered by &nbsp; <br /><span className="font-weight-bold Zigram">ZIGRAM</span></div>
              </span></div> :
            <div title={projectName} className="mt-2 h-100  d-flex flex-row align-items-center justify-content-center" >
              {projectName ? <span className={`align-items-center f-16 nav-link__projectName  ${projectName.length > 40 ? " " : " d-flex"}`} title={projectName} >
                {projectName.length > 40 ? <>  <span className="d-inline-block">{projectName.substring(0, 20)}</span><br />
                  <span className="d-inline-block">{`${projectName.substring(20, 38)}...`}</span></> : projectName}
              </span> : <div title={projectName} className="mt-2 h-100  d-flex flex-row align-items-center justify-content-center" >
                <span className="align-items-center f-16 nav-link__projectName  d-flex" title={projectName} >
                  <div className=" poweredBy">powered by &nbsp; <br /><span className="font-weight-bold Zigram">ZIGRAM</span></div>
                </span></div>}
            </div>}
        </div>
        {/* <div className="company-container__close">
        <div>
          <img
            className="inner-site-brand-logo-img h-100 bg-white"
            src={projectImage ? `data:image/png;base64,${projectImage}` : Logo}
            alt="Pre-Screening Solution"
          />
        </div>
      </div> */}
        <div className="scroll-container ">
          <div className="nav-container">
            <nav className="site-navigation-panel__nav">
              <ul>
                <Accordion defaultActiveKey="0" >
                  {props?.main ? SIDEBAR_MAIN_NAV_OPTIONS.map((navItem, i) => {
                    return (
                      navItem?.subOption
                        ? <li key={`${i}-nav-main-ind`} data-toggle="tooltip" title={navItem.title} className="li-tag">
                          <Accordion.Toggle
                            // as={Card.Header}
                            eventKey={navItem.key}
                            className={"d-flex align-items-center nav-link my-2 w-100 accordion-head" + (props?.location?.pathname.includes(navItem.pathWith) ? " active" : "")}
                            onClick={() => setArrow(!Arrow)}
                          // bsPrefix={bsPrefix}
                          >
                            <span className="nav-link__icon-container">
                              {navItem.icon}
                            </span>
                            <div className="d-flex w-100 justify-content-between">  <span className="nav-link__title d-flex justify-content-between w-100 align-item-center"><span className="ml-2 fw-600">{navItem.title} </span><span>{Arrow === true ? <i className="fa fa-chevron-up f-12 mr-2"></i> : <i className="fa fa-chevron-down f-12 mr-2"></i>}</span></span>  </div>
                          </Accordion.Toggle>
                          <Accordion.Collapse eventKey={navItem.key}>
                            <div className="pl-3">
                              {navItem.subOption.map(opList => <NavLink
                                key={`${opList.title}-in-sub`}
                                className="sub-link"
                                // onClick={() => onClickNev(opList.linkTo)}
                                to={{
                                  pathname: opList.linkTo,
                                  state: {
                                    superAdmin: userRoleInfo === "Super Admin"
                                  },
                                }}
                              >
                                <span className="nav-link__title d-flex justify-content-start align-items-center mb-10"><div className="dot-nev"></div><span className="ml-3 fw-600">{opList.title}</span></span>
                              </NavLink>
                              )}
                            </div>
                          </Accordion.Collapse>
                        </li>
                        :
                         <li key={`inner-nv-${i}`} data-toggle="tooltip" title={navItem.title} className="li-tag">
                          <NavLink
                            className="nav-link my-2 w-100"
                            to={{
                              pathname: navItem.linkTo,
                              state: {
                                projectName: projectName,
                                projectImage: projectImage,
                                projectId: projectId,
                                roleId: roleId,
                                roleName: roleName,
                                apcFrameworkId: apcFrameworkId,
                              },
                            }}
                          >
                            <span className="nav-link__icon-container">
                              {navItem.icon}
                            </span>
                            <span className="nav-link__title"><span className="ml-2 fw-600">{navItem.title}</span></span>
                          </NavLink>
                        </li>
                    );
                  })
                    : SIDEBAR_NAV_OPTIONS.filter((nav) =>
                      nav.roles.includes(userRoleInfo)
                    ).map((navItem, i) => {
                      return (
                        navItem?.subOption
                          ? <li key={`${i}-ot-option-nev`} data-toggle="tooltip" title={navItem.title} className="li-tag">
                            <Accordion.Toggle
                              // as={Card.Header}
                              eventKey={navItem.key}
                              className={"d-flex align-items-center nav-link my-2 w-100 accordion-head" + (props?.location?.pathname.includes(navItem.pathWith) ? " active" : "")}
                              onClick={() => {
                                if (Arrow === navItem.key) setArrow(-1)
                                else setArrow(navItem.key)
                              }}
                            // bsPrefix={bsPrefix}
                            >
                              <span className="nav-link__icon-container">
                                {navItem.icon}
                              </span>
                              <span className="nav-link__title d-flex justify-content-between w-100 align-item-center"><span className="ml-2 fw-600">{navItem.title} </span><span>{Arrow === navItem.key ? <i className="fa fa-chevron-up f-12 mr-2"></i> : <i className="fa fa-chevron-down f-12 mr-2"></i>}</span></span>
                            </Accordion.Toggle>
                            <Accordion.Collapse eventKey={navItem.key}>
                              <div className="pl-5">
                                {navItem.subOption.map(opList => <NavLink
                                  className="sub-link"
                                  key={`${opList.title}-ot-in-nav`}
                                  onClick={() => onClickNev(opList.linkTo)}
                                  to={{
                                    pathname: opList.linkTo,
                                    state: {
                                      projectName: projectName,
                                      projectImage: projectImage,
                                      projectId: projectId,
                                      roleId: roleId,
                                      roleName: roleName,
                                      apcFrameworkId: apcFrameworkId,
                                    },
                                  }}
                                >
                                  <span className="nav-link__title d-flex justify-content-start align-items-center mb-10"><div className="dot-nev"></div><span className="ml-3 fw-600">{opList.title}</span></span>
                                </NavLink>
                                )}
                              </div>
                            </Accordion.Collapse>
                          </li>
                          : isFreeSSOuser && navItem?.disable ?
                          <li key={`inner-nv-${i}`} data-toggle="tooltip" title={navItem.title} className={`li-tag nav-link my-2 w-100 disable-content`}>
                            <span className="nav-link__icon-container">
                              {navItem.icon}
                            </span>
                            <span className="nav-link__title"><span className="ml-2 fw-600">{navItem.title}</span></span>
                          </li>:
                           <li key={i} data-toggle="tooltip" title={navItem.title} className="li-tag">
                            <NavLink
                              className="nav-link my-2 w-100"
                              onClick={() => onClickNev(navItem.linkTo)}
                              to={{
                                pathname: navItem.linkTo,
                                state: {
                                  projectName: projectName,
                                  projectImage: projectImage,
                                  projectId: projectId,
                                  roleId: roleId,
                                  roleName: roleName,
                                  apcFrameworkId: apcFrameworkId,
                                },
                              }}
                            >
                              <span className="nav-link__icon-container">
                                {navItem.icon}
                              </span>
                              <span className="nav-link__title"><span className="ml-2 fw-600">{navItem.title}</span></span>
                            </NavLink>
                          </li>
                      );
                    })}
                </Accordion>
              </ul>
            </nav>
          </div >


        </div>
        <div className="nav-container py-0 logoutButton w-100">
          {/* <div className="w-100 divider-nav"></div> */}
          <nav className="site-navigation-panel__nav">
            <ul>
              {
                QUICK_LINK.map((navItem, i) => {
                  return (
                    <li key={`${i}-quick-nev-link`} data-toggle="tooltip" title={navItem.title} className="li-tag" onClick={() => onLinkClick({
                      pathname: navItem.linkTo,
                      state: {
                        projectName: projectName,
                        projectImage: projectImage,
                        projectId: projectId,
                        roleId: roleId,
                        roleName: roleName,
                        apcFrameworkId: apcFrameworkId,
                      },
                    })}>
                      <div className="nav-link py-0 w-100"  >
                        <span className="nav-link__icon-container">
                          {navItem.icon}
                        </span>
                        <span className="nav-link__title"><span className="ml-2 fw-600">{navItem.title}</span></span>
                      </div>
                    </li>
                  )
                })
              }
            </ul>
          </nav>
        </div >
      </div >
    </div>
  );
}


const mapStateToProps = (state) => ({
  userProfilePicture: state.user.userData?.userInfo?.UserImage,
  userProfileConstant: state.user.userProfileConstant,
});

export default connect(mapStateToProps)(AppSidebar);
