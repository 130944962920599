import React, { useEffect } from "react";
import { Route, Redirect } from "react-router-dom";
import { get as lodashGet } from "lodash";
import { store } from "services/Redux";
import Cookies from "js-cookie";
import { useKeycloak } from "@react-keycloak/web";
import AuthorizationService from "services/AuthorizationService";
import { useDispatch } from "react-redux";
// import { checkValidSession } from "utils/CheckValidationSession";
// import keycloak from "config/Keycloak";
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch()
  const storeData = store.getState();
  const loginCheckToken = Cookies.get("temp_id");
  const isSSOUser = lodashGet(storeData, "user.userData.userInfo.IsSsoUser", false)
  const localData = localStorage.getItem("isSSOUser")
  const { keycloak, initialized } = useKeycloak()


  let message = localStorage.getItem("inactivityMessage") || ""

  // checkValidSession()

  // useEffect(() => {
  //   if (initialized && keycloak.authenticated) {
  //     const storedToken = localStorage.getItem('kcToken');
  //     const storedRefreshToken = localStorage.getItem('kcRefreshToken');
  //     const storeIdToken = localStorage.getItem('kcIdToken');
  //     if (storedToken && storedRefreshToken) {
  //       keycloak.token = storedToken;
  //       keycloak.refreshToken = storedRefreshToken;
  //       keycloak.idToken = storeIdToken;
  //     }
  //   }
  // }, [initialized])

  useEffect(() => {
    return () => {
      localStorage.removeItem("isSSOUser")
    }
  }, [])




  const redirectUrl = props => {
    if (props.location.pathname.includes("resources")) {
      let tempVar = props.location.pathname.split("/");
      if (!!tempVar[2]) {
        let tempVar1 = tempVar[2].split("%20").join(" ");
        sessionStorage.setItem("insight-type", tempVar1);
        // sessionStorage.setItem("returnUrl", "/dashboard" );
        window.history.pushState({}, '', '/resources');
      }
    }
  };

  const returnUrl = props => {
    if (props.location.pathname.includes("resources")) {
      let tempVar = props.location.pathname.split("/");
      if (!!tempVar[2]) {
        let tempVar1 = tempVar[2].split("%20").join(" ");
        sessionStorage.setItem("insight-type", tempVar1);
        sessionStorage.setItem("returnUrl", "/" + tempVar[1]);
      }
      else {
        sessionStorage.setItem("insight-type", "Weekly Bulletin");
        sessionStorage.setItem("returnUrl", "/resources");
      }

    }

    else {
      if (props.location.search === '')
        sessionStorage.setItem("returnUrl", props.location.pathname);
      else
        sessionStorage.setItem("returnUrl", props.location.pathname + props.location.search);
    }
  };

  const clearData = () => {
    AuthorizationService.clearUserData()
    dispatch({ data: null, type: "USER_LOGOUT" });
  }

  return (
    <Route
      {...rest}
      render={props => {
        if (initialized) {
          if (!!keycloak.authenticated) {
            redirectUrl(props);
            return <Component {...props} />;
          } else {
            returnUrl(props);
            clearData()
            // localStorage.removeItem("inactivityMessage")
            return window.open(`${process.env.REACT_APP_KEYCLAOK_URL}realms/ZIGRAM/protocol/openid-connect/auth?client_id=${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}&response_type=code${message ? `&inactivityMessage=${message}` : ""}`, "_self")
          }
        }
      }}
    />
  );
};
export default ProtectedRoute;
