export const UPDATE_VIEW = "UPDATE_VIEW";

export function setNewView(data) {
    return { type: UPDATE_VIEW, data };
}

const HomeViewAction = {
    setNewView,
};

export default HomeViewAction;